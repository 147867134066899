// Production constants
export const API_URL = "https://api.starlaunch.com";
export const starsAdd = "HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW";
export const hydrazineAdd = "4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda";
export const usdcAdd = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
export const cadetProgramAdd = "F9zBS4xUUtGUfBddkAhXaiFxYkJbUpRVUoic4FxpnXR4";
export const vaultProgramAdd = "GZCipu6DQWQ1LTXuDg5yrvToz2LnUNxJzbVwy6x4sHC8";
export const idoProgramAdd = "7im9orzthW5w58Thf3d7RjhPCPpqGMGhgtZ11tQfgLxp";
export const claimsProgramAdd = "jcamzkfhgokLg2oCRKH6mckg47t37ckHLyRMHN2si8s";
export const privateClaimsProgramAdd = "6zwy8WtDLwp8gDfxbWH2peECFy2YWJHHfbbHXySBCJv2";

// Devnet constants
// export const API_URL = "http://dev-api.starlaunch.com";
// export const starsAdd = "HLujbavbSqnuWcEXpz9AdJfhu4oPJHXCzWkTqFQcsF6V";
// export const hydrazineAdd = "4uYvHeAyWozDfKjNuqn3PQzP1sQBLE8oL98a5MR3m4Sb";
// export const usdcAdd = "CYoKH2gmndgTky3CEdrXhNRXjiHvUJ1UFkcjjjGWBqun";
// export const cadetProgramAdd = "D28TWWwqMj8BAJ8v7Yfj45PxXzDNoGDMkJWuK75zU4v1"; // ?
// export const vaultProgramAdd = "DHjeqBzMPkMp8kGe9oSW3Z3eEDiBdZkuukxNBzMQbiJ6"; // ?
// export const idoProgramAdd = "7im9orzthW5w58Thf3d7RjhPCPpqGMGhgtZ11tQfgLxp"; // ?
// export const claimsProgramAdd = "5VNPPVs6JzmR9Hn4vnN1uk2y3JXZeDBjYx6X8ViNkP8V"; // ?

// Test constants
// export const API_URL = "http://localhost:1337";
// export const starsAdd = "FofXjmyFpNKGPUP9SJQk61zXe1zQAQEUo2BA7UT1CFFS";
// export const hydrazineAdd = "7MdRtgeHEYqfTattjttSEHkcdJP7kzKeWhBQZSqoNRVX";
// export const usdcAdd = "9mbB4eAY7TijVCwwP9uRRed4WE2xo7Gaq9uijUTh4yuB";
// export const cadetProgramAdd = "D28TWWwqMj8BAJ8v7Yfj45PxXzDNoGDMkJWuK75zU4v1";
// export const vaultProgramAdd = "DHjeqBzMPkMp8kGe9oSW3Z3eEDiBdZkuukxNBzMQbiJ6";
// export const idoProgramAdd = "7im9orzthW5w58Thf3d7RjhPCPpqGMGhgtZ11tQfgLxp";
// export const claimsProgramAdd = "5VNPPVs6JzmR9Hn4vnN1uk2y3JXZeDBjYx6X8ViNkP8V";