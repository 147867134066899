import "./index.css";
import BigNumber from 'bignumber.js';
import moment from 'moment';

function ProjectClaims(props) {
  return (
    <div className="px-2 mt-4 d-flex flex-column">
        <ProjectDetails data={props.data}/>

        <div className="d-flex row">
            {props.data.tranches.map(function(d,idx) {
                return (
                  <TrancheDetails data={d} pool={props.data.poolName} decimals={props.data.decimals} claimTokens={props.claimTokens}/>
                )
              })
            }
            {/* <TrancheDetails 
                title={`Tranche 0`}
            />
            <TrancheDetails 
                title={`Tranche 1`}
            />
            <TrancheDetails 
                title={`Tranche 2`}
            />
            <TrancheDetails 
                title={`Tranche 3`}
            /> */}
        </div>
    </div>
  );
}

function ProjectDetails(props) {
    return (
      <div className="ctx-container px-2">
        <div className="project-details d-flex">
            <div className="col-sm-12 col-md-9 p-4 project-stats">  
                <div>
                    <div className="project-title">{ props.data.name }</div>
                    {/* <div className="d-flex">
                        <div>Total Tokens to Claim : </div>
                        <div>100000</div>
                    </div>
                    <div className="d-flex">
                        <div>Claimed So far : </div>
                        <div>10</div>
                    </div>
                    <div className="d-flex">
                        <div>Number of Tranches : </div>
                        <div>3</div>
                    </div> */}
                </div> 
            </div>
            <div className="col-3 d-flex justify-content-center">
                <img src={ props.data.logo } />
            </div>
        </div>
      </div>
    );
}
  
function TrancheDetails(props) {
    function claimTokens() {
        props.claimTokens(props.pool, props.data.mint, props.data.no, props.data.amount, props.data.index, props.data.proof);
    }
    return (
      <div className="tranche-details p-4 col-4">
        <div className="tranche-container">
            <div className="tranche-heading">Tranche {props.data.no}</div>
            {props.data.amount && new BigNumber(props.data.amount).gt(0) ?
                <>
                    <div className="tranche-text d-flex flex-row">
                        <div>Claim Available</div>
                        <div>{new BigNumber(props.data.amount).div(new BigNumber(10).pow(new BigNumber(props.decimals))).toFixed(4)}</div>
                    </div>
                    <div className="tranche-claim-status m-1 text-green font-x justify-content-center">
                        {
                            ((props.data.claimMode === 1 && props.data.claimStatus === 2) || (props.data.claimMode === 2 && props.data.timePassed)) && !props.data.claimed ?
                            <button className="vault-btn btn-orange border-0" onClick={claimTokens}>Claim</button>
                            :
                            props.data.claimed ?
                            <span>Already claimed</span> :
                            props.data.claimMode === 1 && props.data.claimStatus !==2 ?
                            <span>Claims not open</span> :
                            props.data.claimMode === 2 && !props.data.timePassed ?
                            <span>Claim opens on {moment.unix(props.data.claimDate).utc().format("MMM DD YYYY, h:mm:ss A")} UTC</span> :
                            <span>Claim not found</span>
                        }
                    </div>
                </> :
                <div className="tranche-text d-flex flex-row">
                    <div>No Claim Available</div>
                </div>
            }
        </div>
      </div>
    );
}
  

export { ProjectClaims }