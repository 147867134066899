import React, {Fragment, useState, useEffect} from 'react';
import Heading from "../../Components/Heading";
import Profile from "../../Components/Profile";
import SqaureBraket from "../../Components/SquareBracket";
import TopOfficersStats from "../../Components/TopOfficersStats";
import Mission from "../../Components/Mission";

import cadetIdl from '../../web3/idl/cadet.json';
import { Program, Provider, web3, BN, utils } from '@project-serum/anchor';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import toast from 'react-hot-toast';
import { useAnchorWallet, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

import { starsAdd, hydrazineAdd, cadetProgramAdd } from '../../constants/constants';

import BigNumber from 'bignumber.js';

import "./index.css";

function Home() {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { SystemProgram, SYSVAR_RENT_PUBKEY } = web3;
  const starsMint = new web3.PublicKey(starsAdd);
  const hydrazineMint = new web3.PublicKey(hydrazineAdd);
  // const starsMint = new web3.PublicKey("FofXjmyFpNKGPUP9SJQk61zXe1zQAQEUo2BA7UT1CFFS");
  // const hydrazineMint = new web3.PublicKey("7MdRtgeHEYqfTattjttSEHkcdJP7kzKeWhBQZSqoNRVX");
  const [provider, setProvider] = useState(null);
  const cadetProgramID = new web3.PublicKey(cadetProgramAdd);
  const [cadetData, setCadetData] = useState('fetching');
  const [cadetText, setCadetText] = useState('Please connect your wallet');
  const [cadetAccount, setCadetAccount] = useState(null);
  const [cadetId, setCadetId] = useState(null);
  const [cadetRank, setCadetRank] = useState(null);
  const [cadetMp, setCadetMp] = useState(null);
  const [cadetLvl, setCadetLvl] = useState(null);
  const [cadetStars, setCadetStars] = useState(null);
  const [cadetN2h4, setCadetN2h4] = useState(null);
  const DEFAULT_TIMEOUT = 31000;
  useEffect(()=>{
    if (wallet.publicKey) {
        console.log(wallet.publicKey.toBase58());
        setCadetData('connected');
        setProvider(new Provider(connection,wallet,Provider.defaultOptions()));
        // let message = new TextEncoder().encode("Hello boy");
        // wallet.signMessage(message);
        // await getAccountData();
    } else {
      setCadetData('not connected')
    }
}, [wallet.publicKey]);

useEffect(() => {
  if (provider) {
    getAccountData();
  }
},[provider]);  

async function getAddys() {
  const [registrationAccount, registrationBump] = await web3.PublicKey.findProgramAddress(
    [Buffer.from("Cadet_Reg")],
    cadetProgramID
  );
  const [cadetAcct, cadetAcctBump] = await web3.PublicKey.findProgramAddress(
    [provider.wallet.publicKey.toBuffer(),Buffer.from("Cadet_Reg"),Buffer.from("register_cadet")],
    cadetProgramID
  );
  return {registrationAccount: registrationAccount, registrationBump: registrationBump,
          cadetAcct: cadetAcct, cadetAcctBump: cadetAcctBump}
}


async function findATA(wallet,mint) {
  const ata = await web3.PublicKey.findProgramAddress([wallet.toBuffer(),TOKEN_PROGRAM_ID.toBuffer(),mint.toBuffer()],
                  ASSOCIATED_TOKEN_PROGRAM_ID);
  console.log(ata[0]);
  return ata[0];
}

async function getAccountData() {
  const accts = await getAddys();
  console.log(accts.cadetAcct);
  const cadetInit = await connection.getBalance(accts.cadetAcct);
  if (cadetInit > 0) {
    setCadetAccount(accts.cadetAcct);
    const cadetProgram = new Program(cadetIdl, cadetProgramID, provider);
    const registrationInfo = await cadetProgram.account.registrationAccount.fetch(accts.registrationAccount);
    console.log(registrationInfo);
    console.log(registrationInfo.totalCadets.toString());
    const cadetInfo = await cadetProgram.account.cadetAccount.fetch(accts.cadetAcct);
    console.log(cadetInfo);
    setCadetId("ID " + String(cadetInfo.id.toNumber()).padStart(8, '0'));
    setCadetRank("CADET");
    setCadetLvl(cadetInfo.level.toString());
    setCadetMp(cadetInfo.exp.toString());
    const starATA = await findATA(provider.wallet.publicKey,starsMint);
    const acctBal = new BN(await connection.getBalance(starATA));
    if (acctBal.isZero()) {
      setCadetStars(0);
    } else {
      const starToken = new Token(connection,starsMint,TOKEN_PROGRAM_ID,provider.wallet.publicKey);
      const starAcctInfo = await starToken.getAccountInfo(starATA);
      if (starAcctInfo.amount.gt(new BN(0))) {
        setCadetStars(new BigNumber(starAcctInfo.amount.toString()).div(new BigNumber(1000000)).toFixed(2));
      } else {
        setCadetStars(0);
      }
    }
    const hydrazineATA = await findATA(provider.wallet.publicKey,hydrazineMint);
    const hydrazineAcctBal = new BN(await connection.getBalance(hydrazineATA));
    if (hydrazineAcctBal.isZero()) {
      setCadetN2h4(0);
    } else {
      const hydrazineToken = new Token(connection,hydrazineMint,TOKEN_PROGRAM_ID,provider.wallet.publicKey);
      const hydrazineAcctInfo = await hydrazineToken.getAccountInfo(hydrazineATA);
      if (hydrazineAcctInfo.amount.gt(new BN(0))) {
        setCadetN2h4(new BigNumber(hydrazineAcctInfo.amount.toString()).div(new BigNumber(1000000)).toFixed(4));
      } else {
        setCadetN2h4(0);
      }
    }
    setCadetData("success");
  } else {
    console.log("Cadet account doesn't exist yet");
    setCadetAccount(null);
    setCadetData("NA");
    setCadetText("Create a New Cadet Account");
  }
}

async function createAccount() {
  try {
    const accts = await getAddys();
    const cadetProgram = new Program(cadetIdl, cadetProgramID, provider);
    const transaction = new web3.Transaction();
    transaction.add(
      cadetProgram.instruction.registerCadet(accts.cadetAcctBump, {
        accounts: {
          userAuthority: provider.wallet.publicKey,
          cadetAccount: accts.cadetAcct,
          registrationAccount: accts.registrationAccount,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        },
      })
    );
    const signature = await provider.wallet.sendTransaction(transaction, connection);
    await connection.confirmTransaction(signature, 'processed');
    toast("Cadet Account Initiated",{
      duration: 3000,
      position: 'bottom-left',
      style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                color: "#FFF"},
      className: 'success-toast',
      icon: '✓',
    });
    setCadetAccount(accts.cadetAcct);
    setCadetData("success");
    await getAccountData();
  } catch(e) {
    console.warn("Failed", e);
    toast(e.message,{
        duration: 2000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
        color: "#FFF"},
        className: 'error-toast',
        icon: '⚠',
    });
  }
}

async function closeAccount() {
  try {
    const accts = await getAddys();
    const cadetProgram = new Program(cadetIdl, cadetProgramID, provider);
    const transaction = new web3.Transaction();
    transaction.add(
      cadetProgram.instruction.resignCadet({
        accounts: {
          userAuthority: provider.wallet.publicKey,
          cadetAccount: accts.cadetAcct,
          registrationAccount: accts.registrationAccount,
        },
      })
    );
    const signature = await provider.wallet.sendTransaction(transaction, connection);
    await connection.confirmTransaction(signature, 'processed');
    toast("Cadet Account Closed",{
      duration: 2000,
      position: 'bottom-left',
      style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                color: "#FFF"},
      className: 'success-toast',
      icon: '✓',
    });
    setCadetAccount(null);
    setCadetData("NA");
    setCadetText("Create a new Cadet Account");
    await getAccountData();
  } catch(e) {
    console.warn("Failed", e);
    toast(e.message,{
        duration: 2000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
                color: "#FFF"},
        className: 'error-toast',
        icon: '⚠',
    });
  }
}

  return (
    <main>
      <div className="page home-page vh-100">
        <div className="container-fluid">
        <div className="top-row row w-100 mt-4">
          <div className="col-sm-12 col-md-6 left-col">
            {cadetData==="not connected" ?
            <div className="row">
              <div className="col">
                <Heading title="Profile" />
              </div>
              <div className="subtext col-12">
                <div className="create-text">{cadetText}</div>
                <WalletMultiButton />
              </div>
              <div className="col">
                <Profile
                  id="ID XXXXX"
                  currentMP="0"
                  totalMP="1000"
                  level="1"
                  rank="CADET"
                  className="blurred"
                />
                <div className="d-flex justify-content-between w-100 blurred">
                  <SqaureBraket classes="col-6 bracket-blue w-48">
                    <div className="stats-container stats-container-left d-flex justify-content-between align-items-baseline">
                      <span className="title-text font-xxl">$STARS</span>
                      <span className="title-value">XXXXXX</span>
                    </div>
                  </SqaureBraket>
                  <SqaureBraket classes="col-6 bracket-orange w-48">
                    <div className="stats-container stats-container-right d-flex justify-content-between align-items-baseline w-100">
                      <span className="title-text font-xxl">$N2H4</span>
                      <span className="title-value">XXXXXX</span>
                    </div>
                  </SqaureBraket>
                </div>
              </div>
            </div>
            : cadetData === "NA" ?
            <div className="row">
              <div className="col">
                <Heading title="Profile" />
              </div>
              <div className="subtext col-12">
                <div className="create-text">{cadetText}</div>
                <button className="create-btn" onClick={createAccount}>Create Account</button>
              </div>
              <div className="col-12">
                <Profile
                  id="ID XXXXX"
                  currentMP="0"
                  totalMP="1000"
                  level="1"
                  rank="CADET"
                  className="blurred"
                />
                <div className="d-flex justify-content-between w-100 blurred">
                  <SqaureBraket classes="col-6 bracket-blue w-48">
                    <div className="stats-container stats-container-left d-flex justify-content-between align-items-baseline">
                      <span className="title-text font-xxl">$STARS</span>
                      <span className="title-value">XXXXXX</span>
                    </div>
                  </SqaureBraket>
                  <SqaureBraket classes="col-6 bracket-orange w-48">
                    <div className="stats-container stats-container-right d-flex justify-content-between align-items-baseline w-100">
                      <span className="title-text font-xxl">$N2H4</span>
                      <span className="title-value">XXXXXX</span>
                    </div>
                  </SqaureBraket>
                </div>
              </div>
            </div>
            :
            <div className="row">
              <div className="col-9">
                <Heading title="Profile" />
              </div>
              <div className="col-12">
                <Profile
                  id={cadetId}
                  currentMP={cadetMp}
                  totalMP="1000"
                  level={cadetLvl}
                  rank={cadetRank}
                />
                <div className="d-flex justify-content-between w-100">
                  <SqaureBraket classes="col-6 bracket-blue w-48">
                    <div className="stats-container stats-container-left d-flex justify-content-between align-items-baseline">
                      <span className="title-text font-xxl">$STARS</span>
                      <span className="title-value font-xxl">{cadetStars}</span>
                    </div>
                  </SqaureBraket>
                  <SqaureBraket classes="col-6 bracket-orange w-48">
                    <div className="stats-container stats-container-right d-flex justify-content-between align-items-baseline w-100">
                      <span className="title-text font-xxl">$N2H4</span>
                      <span className="title-value font-xxl">{cadetN2h4}</span>
                    </div>
                  </SqaureBraket>
                </div>
              </div>
            </div>
            }
            <div className="row">
              <div className="col pt-5">
                <Heading title="Top Officers" />
              </div>
              <div className="col-12">
                <div className="top-officers-header d-flex justify-content-between">
                  <span>Cadet ID</span>
                  <span>MP</span>
                  <span>LVL</span>
                </div>
                <div className="blurred">
                  <SqaureBraket classes="mb-3">
                    <TopOfficersStats
                      name="1. XXXXXXX"
                      mp="XXXX"
                      lvl="XXX"
                      percentage="90"
                    />
                  </SqaureBraket>
                  <SqaureBraket classes="mb-3">
                    <TopOfficersStats
                      name="1. XXXXXXX"
                      mp="XXXX"
                      lvl="XXX"
                      percentage="90"
                    />
                  </SqaureBraket>
                  <SqaureBraket classes="mb-3">
                    <TopOfficersStats
                      name="2. XXXXXXX"
                      mp="XXXX"
                      lvl="XXX"
                      percentage="90"
                    />
                  </SqaureBraket>
                  <SqaureBraket classes="mb-3">
                    <TopOfficersStats
                      name="3. XXXXXXX"
                      mp="XXXX"
                      lvl="XXX"
                      percentage="90"
                    />
                  </SqaureBraket>
                  <SqaureBraket classes="mb-3">
                    <TopOfficersStats
                      name="4. XXXXXXX"
                      mp="XXXX"
                      lvl="XXX"
                      percentage="90"
                    />
                  </SqaureBraket>
                  <SqaureBraket classes="mb-3">
                    <TopOfficersStats
                      name="5. XXXXXXX"
                      mp="XXXX"
                      lvl="XXX"
                      percentage="90"
                    />
                  </SqaureBraket>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 right-col">
            <div className="col-12 daily-missions-container">
              <Heading title="Missions" />
              <Mission
                title="Mission 1"
                description="xxxxx xxxxxx xxxxx xxxx xxxxxxx xxxxxx xxxxxxxxx xxxx xxxxxxx xxxxxx xxxxxxxx xxxxx xxxxxxxx xxxxxx xxxxx xxxxxx xxxxx xxxxxx xxxxx xxxx xxxxxxx xxxxxx xxxxxxxxx xxxx xxxxxxx xxxxxx xxxxxxxx xxxxx xxxxxxxx xxxxxx xxxxx xxxxxx xxxxx xxxxxx xxxxx xxxx xxxxxxx xxxxxx xxxxxxxxx xxxx xxxxxxx xxxxxx xxxxxxxx xxxxx xxxxxxxx xxxxxx xxxxx xxxxxx"
              />
              <div className="mission-divider" />
              <Mission
                title="Mission 2"
                description="xxxxx xxxxxx xxxxx xxxx xxxxxxx xxxxxx xxxxxxxxx xxxx xxxxxxx xxxxxx xxxxxxxx xxxxx xxxxxxxx xxxxxx xxxxx xxxxxx xxxxx xxxxxx xxxxx xxxx xxxxxxx xxxxxx xxxxxxxxx xxxx xxxxxxx xxxxxx xxxxxxxx xxxxx xxxxxxxx xxxxxx xxxxx xxxxxx xxxxx xxxxxx xxxxx xxxx xxxxxxx xxxxxx xxxxxxxxx xxxx xxxxxxx xxxxxx xxxxxxxx xxxxx xxxxxxxx xxxxxx xxxxx xxxxxx"
              />
            </div>
          </div>
        </div>
      </div>
      </div>
    </main>
  );
}

export default Home;
