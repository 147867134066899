import React, {Fragment, useState, useEffect} from 'react';
import moment from 'moment';

import {API_URL} from '../../constants/constants';

import {
  Link
} from "react-router-dom";

import "./index.css";
import { IDOCard } from "../../Components/Tokens";
import HeadingDotted from "../../Components/HeadingDotted";

function Tokens() {
  const [ongoingSales, setOngoingSales] = useState([]);
  const [upcomingSales, setUpcomingSales] = useState([]);
  const [completedSales, setCompletedSales] = useState([]);
  useEffect(()=>{
    console.log("Inside this one");
    fetch(API_URL+"/sales/", {
      "method": "POST",
      "headers": {"content-type": "application/json", "accept": "application/json"},
      }).then(res=>res.json())
      .then((result)=>{
          console.log(result.data.sales_json.sales);
          let ongoingList = [];
          let upcomingList = [];
          let completedList = [];
          let curTime = moment().unix();
          for (let i=0; i<result.data.sales_json.sales.length; i++) {
            if (curTime < Number(result.data.sales_json.sales[i].registrationStartTime)) {
              upcomingList.push(result.data.sales_json.sales[i]);
            } else if (curTime >= Number(result.data.sales_json.sales[i].registrationStartTime) && result.data.sales_json.sales[i].status !== "Completed") {
              ongoingList.push(result.data.sales_json.sales[i]);
            } else if (result.data.sales_json.sales[i].status === "Completed") {
              completedList.push(result.data.sales_json.sales[i]);
            }
          }
          console.log(completedList);
          console.log(upcomingList);
          setOngoingSales(ongoingList);
          setUpcomingSales(upcomingList);
          setCompletedSales(completedList);
        });
  },[]);
 

  return (
    <main>
      <div className="page vault-page tokens-page vh-100">
        <div className="container">
        <div className="row justify-content-around">
          <div className="col-4  mt-4">
            <HeadingDotted title="Ongoing IDOS" classes={"text-center"}/>
          </div>
        </div>
        <div className="row p-4 m-4 ido-cards d-flex flex-row">
            {ongoingSales.map(function(d,idx) {
               return (
                <div className="col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center ido-card">
                  <Link to={{ pathname: "/sale-detail", state: {...d}}}>
                    <IDOCard
                      data = {d}
                      />
                  </Link>
                </div>
                )
              })
            }
        </div>
        <div className="row justify-content-around">
          <div className="col-4  mt-4">
            <HeadingDotted title="Upcoming IDOS" classes={"text-center"}/>
          </div>
        </div>
        <div className="row p-4 m-4 ido-cards d-flex flex-row">
            {upcomingSales.map(function(d,idx) {
               return (
                <div className="col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center ido-card">
                  {d.poolname === "" ?
                  <IDOCard
                  data = {d}
                  /> :
                  <Link to={{ pathname: "/sale-detail", state: {...d}}}>
                    <IDOCard
                      data = {d}
                      />
                  </Link>
                  }
                </div>
                )
              })
            }
        </div>
       <div className="row justify-content-around">
          <div className="col-4  mt-4">
            <HeadingDotted title="Completed IDOS" classes={"text-center"}/>
          </div>
        </div>
        <div className="row p-4 m-4 ido-cards d-flex flex-row">
            {completedSales.map(function(d,idx) {
               return (
                <div className="col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center ido-card">
                  <Link to={{ pathname: "/sale-detail", state: {...d}}}>
                    <IDOCard
                      data = {d}
                      />
                  </Link>
                </div>
                )
              })
            }
        </div>
      </div>
      </div>
    </main>
  );
}

export default Tokens;
