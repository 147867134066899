import React, {Fragment, useState, useEffect} from 'react';

import {API_URL} from '../../constants/constants';

import { Program, Provider, web3, BN, utils } from '@project-serum/anchor';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import toast from 'react-hot-toast';
import { useAnchorWallet, useConnection, useWallet } from '@solana/wallet-adapter-react';
import {
  Link
} from "react-router-dom";
import Countdown from 'react-countdown';
import cadetIdl from '../../web3/idl/cadet.json';
import idoIdl from '../../web3/idl/ido.json';

import BigNumber from "bignumber.js";
import moment from 'moment';

import HeadingDotted from "../../Components/HeadingDotted";
import SqaureBraket from "../../Components/SquareBracket";
import StatusBarTop from "../../Components/StatusBarTop";
import "./index.css";
import { ConnectWallet} from "../../Components/Vault";
import {Form, InputGroup, Row, Col, FormControl } from 'react-bootstrap';
import ProgressBar from "../../Components/ProgressBar";
import {starsAdd, hydrazineAdd, usdcAdd, cadetProgramAdd, idoProgramAdd} from '../../constants/constants';

function Ido(props) {
  const [saleData, setSaleData] = useState({});
  useEffect(()=>{
    if (props.location.state) {
      // console.log(props.location.state);
      setSaleData(props.location.state);
    }
  },[]);
  const { connection } = useConnection();
  const wallet = useWallet();
  const { SystemProgram, SYSVAR_RENT_PUBKEY } = web3;
  const [walletData, setWalletData] = useState('fetching');
  const starsMint = new web3.PublicKey(starsAdd);
  const hydrazineMint = new web3.PublicKey(hydrazineAdd);
  const usdcMint = new web3.PublicKey(usdcAdd);
  const idoProgramName = "ido_vault";
  const [provider, setProvider] = useState(null);
  const cadetProgramID = new web3.PublicKey(cadetProgramAdd);
  const idoProgramID = new web3.PublicKey(idoProgramAdd);
  const [cadetAccount, setCadetAccount] = useState(null);
  const [idoAccount, setIdoAccount] = useState(null);
  const [mainData, setMainData] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [poolData, setPoolData] = useState(null);
  const [participantInfo, setParticipantInfo] = useState(null);
  const [currentStage, setCurrentStage] = useState("");
  const [burned, setBurned] = useState(0);
  const [burnAmount, setBurnAmount] = useState("");
  const [buyAmount, setBuyAmount] = useState("");
  const [buyFcfsAmount, setBuyFcfsAmount] = useState("");
  const [vestedAmount, setVestedAmount] = useState(0);
  const [kyc, setKyc] = useState(false);
  const [allocation, setAllocation] = useState(false);
  const [allocationAmt, setAllocationAmt] = useState(0);
  const [allocationIndex, setAllocationIndex] = useState(0);
  const [allocationProof, setAllocationProof] = useState(null);
  
  useEffect(()=>{
    if (wallet.publicKey) {
        // console.log(wallet.publicKey.toBase58());
        setWalletData('connected');
        setProvider(new Provider(connection,wallet,Provider.defaultOptions()));
        // let message = new TextEncoder().encode("Hello boy");
        // wallet.signMessage(message);
        // await getAccountData();
    } else {
      setWalletData('not connected');
    }
  }, [wallet.publicKey]);

  useEffect(() => {
    if (provider) {
      getAccountData();
      // console.log("Found provider");
    }
  },[provider]);

  function updateBurnAmount(e) {
    setBurnAmount(e.target.value);
  }

  function updateBuyAmount(e) {
    setBuyAmount(e.target.value);
  }
  
  function updateBuyFcfsAmount(e) {
    setBuyFcfsAmount(e.target.value);
  }

  function fillMaxAllocation() {
    let overallMax = new BigNumber(allocationAmt).div(new BigNumber(1000000));
    let alreadyBought = new BigNumber(participantInfo.bought.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000));
    setBuyAmount(overallMax.minus(alreadyBought).toFixed(6,1));
  }

  function fillMaxFcfsAllocation() {
    let overallMax = new BigNumber(poolData.openMaxAlloc.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000));
    let alreadyBought = new BigNumber(participantInfo.boughtOpen.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000));
    setBuyFcfsAmount(overallMax.minus(alreadyBought).toFixed(6,1));
  }

  async function getAddys() {
    const [mainData, mainDataBump] = await web3.PublicKey.findProgramAddress(
      [Buffer.from(idoProgramName)],
      idoProgramID
    );
    const [cadetAcct, cadetAcctBump] = await web3.PublicKey.findProgramAddress(
      [provider.wallet.publicKey.toBuffer(),Buffer.from("Cadet_Reg"),Buffer.from("register_cadet")],
      cadetProgramID
    );
    const [pool, poolBump] = await web3.PublicKey.findProgramAddress(
      [Buffer.from(saleData.poolname)],
      idoProgramID
    );
    const [idoAcct, idoAcctBump] = await web3.PublicKey.findProgramAddress(
      [provider.wallet.publicKey.toBuffer(), Buffer.from(idoProgramName), Buffer.from("pool_user")],
      idoProgramID
    );
    const [ppt, pptBump] = await web3.PublicKey.findProgramAddress(
      [provider.wallet.publicKey.toBuffer(), Buffer.from(saleData.poolname), Buffer.from("pool_participant")],
      idoProgramID
    );
    setMainData(mainData);
    return {mainData: mainData, cadetAcct: cadetAcct,
            pool: pool, idoAcct: idoAcct, idoAcctBump: idoAcctBump,
            ppt: ppt, pptBump: pptBump}
  }
  
  async function findATA(wallet,mint) {
    const ata = await web3.PublicKey.findProgramAddress([wallet.toBuffer(),TOKEN_PROGRAM_ID.toBuffer(),mint.toBuffer()],
                    ASSOCIATED_TOKEN_PROGRAM_ID);
    // console.log(ata[0]);
    return ata[0];
  }

  const toBytes32Array = (b) => {
    const buf = Buffer.alloc(32);
    b.copy(buf, 32 - b.length);  
    return Array.from(buf);
  };

  async function getAccountData() {
    const accts = await getAddys();
    const idoProgram = new Program(idoIdl, idoProgramID, provider);
    const mainData = await idoProgram.account.mainData.fetch(accts.mainData);
    const poolInfo = await idoProgram.account.pool.fetch(accts.pool);
    setMainData(mainData);
    // console.log(poolInfo);
    console.log(poolInfo.totalParticipants.toString());
    setPoolData(poolInfo);
    let curTime = moment().unix();
    let stage;
    // console.log(curTime);
    // console.log(poolInfo.idoTimes.registrationEnd.toNumber());
    // console.log(poolInfo.idoTimes.saleStart.toNumber());
    if (curTime < poolInfo.idoTimes.registrationStart.toNumber()) {
      stage = "Upcoming";
    } else if (curTime >= poolInfo.idoTimes.registrationStart.toNumber() && curTime < poolInfo.idoTimes.registrationEnd.toNumber()) {
      stage = "Hydrazine";
    } else if (curTime >= poolInfo.idoTimes.registrationEnd.toNumber() && curTime < poolInfo.idoTimes.saleStart.toNumber()) {
      stage = "Setting Allocations";
    } else if (curTime >= poolInfo.idoTimes.saleStart.toNumber() && curTime < poolInfo.idoTimes.saleEnd.toNumber()) {
      stage = "Whitelist Sale";
    } else if (curTime >= poolInfo.idoTimes.saleEnd.toNumber() && curTime >= poolInfo.idoTimes.openSaleStart.toNumber() && poolInfo.state === 2) {
      stage = "FCFS Sale";
    } else if (poolInfo.state === 3) {
      stage = "Completed";
    } else if (poolInfo.state === 4) {
      stage = "Claims Open";
    } else {
      stage = "Closed";
    }
    setCurrentStage(stage);
    const cadetAccountInit = await connection.getBalance(accts.cadetAcct);
    if (cadetAccountInit > 0) {
      setCadetAccount(accts.cadetAcct);
      const idoAccountInit = await connection.getBalance(accts.idoAcct);
      if (idoAccountInit > 0) {
        setIdoAccount(accts.idoAcct);
        const pptInit = await connection.getBalance(accts.ppt);
        if (pptInit > 0) {
          setParticipant(accts.ppt);
          const pptInfo = await idoProgram.account.participant.fetch(accts.ppt);
          setParticipantInfo(pptInfo);
          setVestedAmount(0);
          if (new BigNumber(curTime).gt(poolInfo.idoTimes.vestingStart.toNumber())) {
            let tranche = new BigNumber(curTime).minus(new BigNumber(poolInfo.idoTimes.vestingStart.toNumber())).idiv(new BigNumber(poolInfo.trancheLength.toNumber()));
            console.log(tranche.toString());
            if (tranche.gt(0)) {
              if (tranche.gt(new BigNumber(poolInfo.tranches.toNumber()))) { tranche = new BigNumber(poolInfo.tranches.toNumber()); }
              let toVest = new BigNumber(pptInfo.bought.toString()).minus(new BigNumber(pptInfo.initialUnlocked.toString()))
                            .times(tranche).idiv(new BigNumber(poolInfo.tranches.toNumber()));
              if (toVest.gt(0) && toVest.gt(new BigNumber(pptInfo.claimed.toString()))) {
                let pendingTokens = toVest.minus(new BigNumber(pptInfo.claimed.toString()));
                setVestedAmount(pendingTokens);
              }
            }
          }  
          // console.log(accts.ppt.burned.toString());
        }
      }
    }
    if (stage === "Hydrazine") {
      try {
        fetch(API_URL+"/salekyc/", {
          "method": "POST",
          "headers": {"content-type": "application/json", "accept": "application/json"},
          "body": JSON.stringify({wallet: provider.wallet.publicKey.toBase58(), name: saleData.poolname})
          }).then(res=>res.json())
          .then((result)=>{
            // console.log(result.data.kyc);
              setKyc(result.data.kyc);
            });
      } catch(e) {
        console.warn("Failed", e);
        toast("Unable to fetch your kyc",{
            duration: 2000,
            position: 'bottom-left',
            style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
            color: "#FFF"},
            className: 'error-toast',
            icon: '⚠',
        });
      }
    }
    if (poolInfo.poolRoot.toString("hex") !== "0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0") {
      try {
        fetch(API_URL+"/allocation/", {
          "method": "POST",
          "headers": {"content-type": "application/json", "accept": "application/json"},
          "body": JSON.stringify({wallet: provider.wallet.publicKey.toBase58(), name: saleData.poolname})
          }).then(res=>res.json())
          .then((result)=>{
            // console.log(result.data.allocation);
              setAllocation(result.data.allocation);
              if (result.data.allocation) {
                setAllocationIndex(result.data.index);
                // console.log(result.data.amount);
                setAllocationAmt(result.data.amount);
                setAllocationProof(result.data.proof);
              }
            });
        } catch(e) {
          console.warn("Failed", e);
          toast("Unable to fetch your allocation",{
              duration: 2000,
              position: 'bottom-left',
              style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
              color: "#FFF"},
              className: 'error-toast',
              icon: '⚠',
          });
        }
    }
    // console.log(moment.duration(100, 'seconds'));
    // console.log(moment.duration(poolInfo.trancheLength.toString()));
  }

  async function makeIdoAccount() {
    try {
      const accts = await getAddys();
      const idoProgram = new Program(idoIdl, idoProgramID, provider);
      const transaction = new web3.Transaction();
      transaction.add(
        idoProgram.instruction.createUser(accts.idoAcctBump, {
          accounts: {
            userAuthority: provider.wallet.publicKey,
            idoUser: accts.idoAcct,
            mainData: accts.mainData,
            systemProgram: SystemProgram.programId,
            rent: SYSVAR_RENT_PUBKEY,
          },
        })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("IDO Account Created",{
        duration: 3000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
        className: 'success-toast',
        icon: '✓',
      });
      setIdoAccount(accts.idoAcct);
      // setVaultAccount(accts.vaultAccount);
      // await getAccountData();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message,{
          duration: 2000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
          color: "#FFF"},
          className: 'error-toast',
          icon: '⚠',
      });
    }
  }

  async function burnHydrazine() {
    try {
      const accts = await getAddys();
      const idoProgram = new Program(idoIdl, idoProgramID, provider);
      const pptInit = new BN(await connection.getBalance(accts.ppt));
      if (pptInit.isZero()) {
        const transaction = new web3.Transaction();
        transaction.add(
          idoProgram.instruction.initParticipant(accts.pptBump, {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              idoUser: accts.idoAcct,
              participant: accts.ppt,
              pool: accts.pool,
              systemProgram: SystemProgram.programId,
              rent: SYSVAR_RENT_PUBKEY,
            },
          })
        );
        const signature = await provider.wallet.sendTransaction(transaction, connection);
        await connection.confirmTransaction(signature, 'processed');
        toast("Participation Initiated",{
          duration: 3000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                    color: "#FFF"},
          className: 'success-toast',
          icon: '✓',
        });
        setParticipant(accts.ppt);
      }
      const burnQty = new BN(new BigNumber(burnAmount).times(new BigNumber(1000000)).toFixed(0));
      // console.log(burnQty.toString());
      const userHydrazine = await findATA(provider.wallet.publicKey,hydrazineMint);
      const [hydrazineVault, hydrazineBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(saleData.poolname), Buffer.from("hydrazine_vault")],
        idoProgramID
      );
      const transaction = new web3.Transaction();
      transaction.add(
        idoProgram.instruction.burnHydrazine(burnQty, {
          accounts: {
            userAuthority: provider.wallet.publicKey,
            idoUser: accts.idoAcct,
            mainData: accts.mainData,
            participant: accts.ppt,
            pool: accts.pool,
            hydrazineMint: hydrazineMint,
            userHydrazine: userHydrazine,
            hydrazineVault: hydrazineVault,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
          },
        })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Successfully burned N2H4",{
        duration: 3000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
        className: 'success-toast',
        icon: '✓',
      });
      window.location.reload();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message,{
          duration: 2000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
          color: "#FFF"},
          className: 'error-toast',
          icon: '⚠',
      });
    }
  }

  async function buyAllocation() {
    try {
      const accts = await getAddys();
      const idoProgram = new Program(idoIdl, idoProgramID, provider);
      const pptInit = new BN(await connection.getBalance(accts.ppt));
      if (pptInit.isZero()) {
        const transaction = new web3.Transaction();
        transaction.add(
          idoProgram.instruction.initParticipant(accts.pptBump, {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              idoUser: accts.idoAcct,
              participant: accts.ppt,
              pool: accts.pool,
              systemProgram: SystemProgram.programId,
              rent: SYSVAR_RENT_PUBKEY,
            },
          })
        );
        const signature = await provider.wallet.sendTransaction(transaction, connection);
        await connection.confirmTransaction(signature, 'processed');
        toast("Participation Initiated",{
          duration: 3000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                    color: "#FFF"},
          className: 'success-toast',
          icon: '✓',
        });
        setParticipant(accts.ppt);
      }
      const buyQty = new BN(new BigNumber(buyAmount).times(new BigNumber(1000000)).toFixed(0,1));
      const index = new BN(allocationIndex);
      const amt = new BN(allocationAmt);
      const userUsdc = await findATA(provider.wallet.publicKey,usdcMint);
      const [usdcVault, usdcBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(saleData.poolname), Buffer.from("usdc_vault")],
        idoProgramID
      );
      const transaction = new web3.Transaction();
      transaction.add(
        idoProgram.instruction.purchaseTokens(index, amt, 
          allocationProof.map((p) => toBytes32Array(Buffer.from(p,"hex"))),
          buyQty,
        {
          accounts: {
            userAuthority: provider.wallet.publicKey,
            idoUser: accts.idoAcct,
            mainData: accts.mainData,
            participant: accts.ppt,
            pool: accts.pool,
            usdcMint: usdcMint,
            userUsdc: userUsdc,
            usdcVault: usdcVault,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
          },
        })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Successfully bought allocation",{
        duration: 3000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
        className: 'success-toast',
        icon: '✓',
      });
      window.location.reload();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message,{
          duration: 2000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
          color: "#FFF"},
          className: 'error-toast',
          icon: '⚠',
      });
    }
  }

  async function buyFcfsAllocation() {
    try {
      const accts = await getAddys();
      const idoProgram = new Program(idoIdl, idoProgramID, provider);
      const pptInit = new BN(await connection.getBalance(accts.ppt));
      if (pptInit.isZero()) {
        const transaction = new web3.Transaction();
        transaction.add(
          idoProgram.instruction.initParticipant(accts.pptBump, {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              idoUser: accts.idoAcct,
              participant: accts.ppt,
              pool: accts.pool,
              systemProgram: SystemProgram.programId,
              rent: SYSVAR_RENT_PUBKEY,
            },
          })
        );
        const signature = await provider.wallet.sendTransaction(transaction, connection);
        await connection.confirmTransaction(signature, 'processed');
        toast("Participation Initiated",{
          duration: 3000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                    color: "#FFF"},
          className: 'success-toast',
          icon: '✓',
        });
        setParticipant(accts.ppt);
      }
      const buyQty = new BN(new BigNumber(buyFcfsAmount).times(new BigNumber(1000000)).toFixed(0,1));
      const index = new BN(allocationIndex);
      const amt = new BN(allocationAmt);
      const userUsdc = await findATA(provider.wallet.publicKey,usdcMint);
      const [usdcVault, usdcBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(saleData.poolname), Buffer.from("usdc_vault")],
        idoProgramID
      );
      const transaction = new web3.Transaction();
      transaction.add(
        idoProgram.instruction.purchaseOpenTokens(index, amt, 
          allocationProof.map((p) => toBytes32Array(Buffer.from(p,"hex"))),
          buyQty,
        {
          accounts: {
            userAuthority: provider.wallet.publicKey,
            idoUser: accts.idoAcct,
            mainData: accts.mainData,
            participant: accts.ppt,
            pool: accts.pool,
            usdcMint: usdcMint,
            userUsdc: userUsdc,
            usdcVault: usdcVault,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
          },
        })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Successfully bought allocation",{
        duration: 3000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
        className: 'success-toast',
        icon: '✓',
      });
      window.location.reload();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message,{
          duration: 2000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
          color: "#FFF"},
          className: 'error-toast',
          icon: '⚠',
      });
    }
  }

  async function claimTokens() {
    try {
      const accts = await getAddys();
      const idoProgram = new Program(idoIdl, idoProgramID, provider);
      const userToken = await findATA(provider.wallet.publicKey,poolData.token);
      const userTokenInit = new BN(await connection.getBalance(userToken)); 
      if (userTokenInit.isZero()) {
          let token = new Token(connection,poolData.token,TOKEN_PROGRAM_ID,provider.wallet.publicKey);
      //    await token.createAssociatedTokenAccount(provider.wallet.publicKey);
          let tx = new web3.Transaction();
          tx.add(
              Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID,TOKEN_PROGRAM_ID,poolData.token,
                      userToken,provider.wallet.publicKey,provider.wallet.publicKey)
          );
          const sign = await provider.wallet.sendTransaction(tx,connection);
          await connection.confirmTransaction(sign,'processed');
          toast(saleData.symbol + " Account created",{
              duration: 2000,
              position: 'bottom-left',
              className: 'success-toast',
              icon: '✓',
          });
      }
      const [tokenVault, tokenBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(saleData.poolname), Buffer.from("token_vault")],
        idoProgramID
      );
      const transaction = new web3.Transaction();
      transaction.add(
        idoProgram.instruction.claimTokens( 
        {
          accounts: {
            userAuthority: provider.wallet.publicKey,
            participant: accts.ppt,
            pool: accts.pool,
            userToken: userToken,
            tokenVault: tokenVault,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
          },
        })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Successfully claimed",{
        duration: 3000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
        className: 'success-toast',
        icon: '✓',
      });
      window.location.reload();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message,{
          duration: 2000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
          color: "#FFF"},
          className: 'error-toast',
          icon: '⚠',
      });
    }
  }

  async function claimVestedTokens() {
    try {
      const accts = await getAddys();
      const idoProgram = new Program(idoIdl, idoProgramID, provider);
      const userToken = await findATA(provider.wallet.publicKey,poolData.token);
      const userTokenInit = new BN(await connection.getBalance(userToken)); 
      if (userTokenInit.isZero()) {
          let token = new Token(connection,poolData.token,TOKEN_PROGRAM_ID,provider.wallet.publicKey);
      //    await token.createAssociatedTokenAccount(provider.wallet.publicKey);
          let tx = new web3.Transaction();
          tx.add(
              Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID,TOKEN_PROGRAM_ID,poolData.token,
                      userToken,provider.wallet.publicKey,provider.wallet.publicKey)
          );
          const sign = await provider.wallet.sendTransaction(tx,connection);
          await connection.confirmTransaction(sign,'processed');
          toast(saleData.symbol + " Account created",{
              duration: 2000,
              position: 'bottom-left',
              className: 'success-toast',
              icon: '✓',
          });
      }
      const [tokenVault, tokenBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(saleData.poolname), Buffer.from("token_vault")],
        idoProgramID
      );
      const transaction = new web3.Transaction();
      transaction.add(
        idoProgram.instruction.claimVestedTokens( 
        {
          accounts: {
            userAuthority: provider.wallet.publicKey,
            participant: accts.ppt,
            pool: accts.pool,
            userToken: userToken,
            tokenVault: tokenVault,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
          },
        })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Successfully claimed",{
        duration: 3000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
        className: 'success-toast',
        icon: '✓',
      });
      window.location.reload();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message,{
          duration: 2000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
          color: "#FFF"},
          className: 'error-toast',
          icon: '⚠',
      });
    }
  }

  return (
    <main>
      <div className="page vh-100 ido tokens-page mt-5">
        <div className="container">
              <div className="row justify-content-around">
                <div className="col-sm-12">
                <div className="back-btn-div mt-3">
                  <Link to="/sales">
                    <button className="vault-btn">
                      <i className="fa fa-arrow-left"/>Back
                    </button>
                  </Link>
                </div>
                { walletData === "fetching" || walletData === "not connected" ?
                  <ConnectWallet /> :
                  <>
                    <div className="row justify-content-around mb-5">
                      <div className="col-sm-12 col-md-8 col-lg-6 mt-1">
                        <HeadingDotted title={saleData.name} classes={"text-center"}/>
                      </div>
                    </div>
                    <div className="row justify-content-around">
                      <div className="col-sm-12 col-md-9">
                        <div className={"row d-flex flex-row"}>
                          <div className={"col-sm-12 col-md-6"}>
                            <div className={"d-flex flex-row justify-content-start pt-3 pb-3 w-50"}>
                                {saleData.telegram !== "" ? <a target="_blank" href={saleData.telegram}><img className={"social-icons"} src={require("../../images/telegram.png").default} /></a> : ""}
                                {saleData.discord !== "" ? <a target="_blank" href={saleData.discord}><img className={"social-icons"} src={require("../../images/discord.png").default} /></a> : ""}
                                <a target="_blank" href={saleData.medium}><img className={"social-icons"} src={require("../../images/medium.png").default} /></a>
                                <a target="_blank" href={saleData.twitter}><img className={"social-icons"} src={require("../../images/twitter.png").default} /></a>
                                <a target="_blank" href={saleData.website}><img className={"social-icons"} src={require("../../images/website.png").default} /></a>
                            </div>
                            <div>
                              <p>{saleData.description}</p>
                            </div>
                          </div>
                          <div className={"col-sm-12 col-md-6 p-4 project-status"} style={{margin: "auto"}}>
                            <div className={"d-flex flex-column text-center w-100"}>
                              <span className={"font-n"}>Current Stage</span>
                              <span className={"m-1 text-green font-x"}>{currentStage ? currentStage : ""}</span>
                            </div>
                            <div className={"d-flex flex-column text-center w-100 mt-3"}>
                              <span className={"font-n"}>Time Remaning</span>
                              <span className={"m-1 text-green font-x"}>
                                {currentStage === "Upcoming" && poolData && poolData.idoTimes ? 
                                  <Countdown date={new BigNumber(poolData.idoTimes.registrationStart.toString()).times(new BigNumber(1000)).toNumber(0)} /> :
                                  currentStage === "Hydrazine" ?
                                  <Countdown date={new BigNumber(poolData.idoTimes.registrationEnd.toString()).times(new BigNumber(1000)).toNumber(0)} /> :
                                  currentStage === "Setting Allocations" ?
                                  <Countdown date={new BigNumber(poolData.idoTimes.saleStart.toString()).times(new BigNumber(1000)).toNumber(0)} />  :
                                  currentStage === "Whitelist Sale" ?
                                  <Countdown onComplete={getAccountData} date={new BigNumber(poolData.idoTimes.saleEnd.toString()).times(new BigNumber(1000)).toNumber(0)} />  :
                                  currentStage === "FCFS Sale" ?
                                  "Till Complete"  :
                                  currentStage === "Completed" ?
                                  "Till Claims Open"  :
                                  currentStage === "Claims Open" ?
                                  "Open forever"  :
                                  "TBA"}
                              </span>
                            </div>
                          </div>
                        </div>
                        {cadetAccount === null ? <div className={"d-flex flex-column w-50 mt-4"}><a href="/" className="vault-btn btn-orange">Make a cadet Account</a></div> : 
                        idoAccount === null ? <div className={"d-flex flex-column w-50 mt-4"}><button onClick={makeIdoAccount} className="vault-btn btn-orange">Make an IDO Account</button></div> :
                        // If both cadet account and ido account exist
                        currentStage === "Hydrazine" ?
                        // If current stage is hydrazine 
                        <>
                        <div className={"d-flex flex-column w-100 progressbar"}>
                          <div className={"d-flex justify-content-around text-white w-100 pb-2"}>
                            <span>Total Contributed: { new BigNumber(poolData.totalHydrazine.toString()).div(new BigNumber(saleData.factor)).lte(new BigNumber(poolData.maxHydrazine.toString())) ? new BigNumber(poolData.totalHydrazine.toString()).div(new BigNumber(1000000)).div(new BigNumber(saleData.factor)).toFixed(2) : new BigNumber(poolData.maxHydrazine.toString()).div(new BigNumber(1000000)).toFixed(2) } N2H4 / {new BigNumber(poolData.maxHydrazine.toString()).div(new BigNumber(1000000)).toFixed(2)} N2H4 Max</span>
                          </div>
                          <div className={"w-100"}>
                            <ProgressBar percentage={new BigNumber(poolData.totalHydrazine.toString()).times(new BigNumber(100)).div(new BigNumber(poolData.maxHydrazine.toString())).div(new BigNumber(saleData.factor)).toFixed(0)} singleUser={true} />
                          </div>
                          <div className={"d-flex justify-content-between text-white w-100 pb-2"}>
                            <span>Individual Limit: {new BigNumber(poolData.individualLimit.toString()).div(new BigNumber(1000000)).toFixed(2)} N2H4</span>
                            <span className={"text-orange"}>Your Contribution: {participant === null ? 0 : participantInfo ? new BigNumber(participantInfo.burned.toString()).div(new BigNumber(1000000)).toFixed(5) : 0} N2H4</span>
                          </div>
                        </div>
                        {kyc ? 
                        <div className={"d-flex flex-column w-100 mt-4"}>
                         
                          <Row className="align-items-center">
                            <Col className="col-3">
                              <div className="form-sample-text">Burn your N2H4</div>
                            </Col>
                            <Col className="col-6">
                            <Form>
                              <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
                                Enter the amount of N2H4
                              </Form.Label>
                              <InputGroup className="mb-2">
                                <FormControl type="number" id="inlineFormInputGroup" placeholder="Enter the amount of N2H4" value={burnAmount} onChange={updateBurnAmount} />
                              </InputGroup>
                            </Form>
                            </Col>
                            { burnAmount === "" ?
                            <Col className="col-2 d-flex">
                              <button className="vault-btn btn-grey mb-2">
                                Burn
                              </button>
                            </Col>
                            :
                            (new BigNumber(poolData.totalHydrazine.toString()).plus(new BigNumber(burnAmount).times(new BigNumber(1000000))).div(new BigNumber(saleData.factor)).lte(new BigNumber(poolData.maxHydrazine.toString()))) ?
                            <Col className="col-2 d-flex">
                              <button className="vault-btn btn-orange mb-2" onClick={burnHydrazine}>
                                Burn
                              </button>
                            </Col>
                            :
                            <Col className="col-3 d-flex">
                              <button className="vault-btn btn-grey mb-2">
                                Max N2H4 Reached
                              </button>
                            </Col>
                            }
                          </Row>
                        </div> :
                        <div className={"d-flex flex-column w-100 mt-4"}>
                          <Row className="align-items-center">
                            <Col className="col-9">
                              <div className="form-sample-text">Your wallet has not been KYCed</div>
                            </Col>
                          </Row>
                        </div>
                        }
                        </> : currentStage === "Setting Allocations" ?
                        // If current stage is setting allocations
                        poolData ? poolData.poolRoot.toString("hex") === "0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0" ?
                        // If allocations are not yet uploaded
                        <>
                        <div className={"d-flex flex-column w-100 mt-4"}>
                         <Row className="align-items-center">
                           <Col className="col-9">
                             <div className="form-sample-text">Please wait while allocations are finalized</div>
                           </Col>
                          </Row>
                        </div>
                        </> :
                        // If allocations are uploaded
                        <>
                          <div className={"d-flex flex-column w-100 progressbar"}>
                            <div className={"d-flex justify-content-around text-white w-100 pb-2"}>
                              <span>Pool Purchased: { new BigNumber(poolData.totalUsdc.toString()).div(new BigNumber(1000000)).toFixed(2) } USDC / {new BigNumber(poolData.usdcAmount.toString()).div(new BigNumber(1000000)).toFixed(2)} USDC Max</span>
                            </div>
                            <div className={"w-100"}>
                              <ProgressBar percentage={new BigNumber(poolData.totalUsdc.toString()).times(new BigNumber(100)).div(new BigNumber(poolData.usdcAmount.toString())).toFixed(0)} singleUser={true} />
                            </div>
                            { allocation ?
                            <div className={"d-flex justify-content-between text-white w-100 pb-2"}>
                              <span>Your Allocation: {new BigNumber(allocationAmt).div(new BigNumber(1000000)).toFixed(3,1)} USDC</span>
                              <span className={"text-orange"}>Your purchased: {participant === null ? 0 : participantInfo ? new BigNumber(participantInfo.bought.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000)).toFixed(3,1) : 0} USDC</span>
                            </div> :
                            <div className={"d-flex justify-content-between text-white w-100 pb-2"}>
                              <span>Your Allocation: 0 USDC</span>
                              <span className={"text-orange"}>Your purchased: 0 USDC</span>
                            </div>
                            }
                          </div>
                        </> :
                        <></> : 
                        currentStage === "Whitelist Sale" ?
                        <>
                          <div className={"d-flex flex-column w-100 progressbar"}>
                            <div className={"d-flex justify-content-around text-white w-100 pb-2"}>
                              <span>Pool Purchased: { new BigNumber(poolData.totalUsdc.toString()).div(new BigNumber(1000000)).toFixed(2) } USDC / {new BigNumber(poolData.usdcAmount.toString()).div(new BigNumber(1000000)).toFixed(2)} USDC Max</span>
                            </div>
                            <div className={"w-100"}>
                              <ProgressBar percentage={new BigNumber(poolData.totalUsdc.toString()).times(new BigNumber(100)).div(new BigNumber(poolData.usdcAmount.toString())).toFixed(0)} singleUser={true} />
                            </div>
                            { allocation ?
                              <div className={"d-flex justify-content-between text-white w-100 pb-2"}>
                                <span>Your Allocation: {new BigNumber(allocationAmt).div(new BigNumber(1000000)).toFixed(3,1)} USDC</span>
                                <span className={"text-orange"}>Your purchased: {participant === null ? 0 : participantInfo ? new BigNumber(participantInfo.bought.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000)).toFixed(3,1) : 0} USDC</span>
                              </div> : 
                              <div className={"d-flex justify-content-between text-white w-100 pb-2"}>
                                <span>Your Allocation: 0 USDC</span>
                                <span className={"text-orange"}>Your purchased: 0 USDC</span>
                              </div>
                            }
                          </div>
                          { allocation ?
                          <div className={"d-flex flex-column w-100 mt-4"}>
                            <Row className="align-items-center">
                              <Col className="col-3">
                                <div className="form-sample-text">Buy allocation</div>
                              </Col>
                              <Col className="col-6">
                              <Form>
                                <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
                                  Enter the amount of USDC
                                </Form.Label>
                                <InputGroup className="mb-2">
                                  <FormControl type="number" id="inlineFormInputGroup" placeholder="Enter the amount of USDC" value={buyAmount} onChange={updateBuyAmount} />
                                </InputGroup>
                              </Form>
                              </Col>
                              <Col className="col-1 d-flex">
                                <button className="vault-btn btn-blue mb-2" onClick={fillMaxAllocation}>
                                  Max
                                </button>
                              </Col>
                              <Col className="col-1 d-flex">
                                <button className="vault-btn btn-orange mb-2" onClick={buyAllocation}>
                                  Buy
                                </button>
                              </Col>
                            </Row>
                          </div> : <></> }
                        </> : 
                        currentStage === "FCFS Sale" ?
                        <>
                          <div className={"d-flex flex-column w-100 progressbar"}>
                            <div className={"d-flex justify-content-around text-white w-100 pb-2"}>
                              <span>Pool Purchased: { new BigNumber(poolData.totalUsdc.toString()).div(new BigNumber(1000000)).toFixed(2) } USDC / {new BigNumber(poolData.usdcAmount.toString()).div(new BigNumber(1000000)).toFixed(2)} USDC Max</span>
                            </div>
                            <div className={"w-100"}>
                              <ProgressBar percentage={new BigNumber(poolData.totalUsdc.toString()).times(new BigNumber(100)).div(new BigNumber(poolData.usdcAmount.toString())).toFixed(0)} singleUser={true} />
                            </div>
                            { allocation ?
                              <div className={"d-flex justify-content-between text-white w-100 pb-2"}>
                                <span>Max Allocation: {new BigNumber(poolData.openMaxAlloc.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000)).toFixed(0,1)} USDC</span>
                                <span className={"text-orange"}>Your purchased: {participant === null ? 0 : participantInfo ? new BigNumber(participantInfo.boughtOpen.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000)).toFixed(3,1) : 0} USDC</span>
                              </div> : 
                              <div className={"d-flex justify-content-between text-white w-100 pb-2"}>
                                <span>Your Allocation: 0 USDC</span>
                                <span className={"text-orange"}>Your purchased: 0 USDC</span>
                              </div>
                            }
                          </div>
                          { allocation ?
                          <div className={"d-flex flex-column w-100 mt-4"}>
                            <Row className="align-items-center">
                              <Col className="col-3">
                                <div className="form-sample-text">Buy allocation</div>
                              </Col>
                              <Col className="col-6">
                              <Form>
                                <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
                                  Enter the amount of USDC
                                </Form.Label>
                                <InputGroup className="mb-2">
                                  <FormControl type="number" id="inlineFormInputGroup" placeholder="Enter the amount of USDC" value={buyFcfsAmount} onChange={updateBuyFcfsAmount} />
                                </InputGroup>
                              </Form>
                              </Col>
                              <Col className="col-1 d-flex">
                                <button className="vault-btn btn-blue mb-2" onClick={fillMaxFcfsAllocation}>
                                  Max
                                </button>
                              </Col>
                              <Col className="col-2 d-flex">
                                <button className="vault-btn btn-orange mb-2" onClick={buyFcfsAllocation}>
                                  Buy FCFS
                                </button>
                              </Col>
                            </Row>
                          </div> : <></> }
                        </> :
                        currentStage === "Completed" ?
                        <>
                          <div className={"d-flex flex-column w-100 progressbar"}>
                            <div className={"d-flex justify-content-around text-white w-100 pb-2"}>
                              <span>Pool Purchased: { new BigNumber(poolData.totalUsdc.toString()).div(new BigNumber(1000000)).toFixed(2) } USDC / {new BigNumber(poolData.usdcAmount.toString()).div(new BigNumber(1000000)).toFixed(2)} USDC Max</span>
                            </div>
                            <div className={"w-100"}>
                              <ProgressBar percentage={new BigNumber(poolData.totalUsdc.toString()).times(new BigNumber(100)).div(new BigNumber(poolData.usdcAmount.toString())).toFixed(0)} singleUser={true} />
                            </div>
                            { allocation ?
                              <div className={"d-flex justify-content-between text-white w-100 pb-2"}>
                                <span>Your Allocation: {new BigNumber(allocationAmt).div(new BigNumber(1000000)).toFixed(3,1)} USDC</span>
                                <span className={"text-orange"}>Your purchased: {participant === null ? 0 : participantInfo ? new BigNumber(participantInfo.bought.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000)).toFixed(3,1) : 0} USDC</span>
                              </div> : 
                              <div className={"d-flex justify-content-between text-white w-100 pb-2"}>
                                <span>Your Allocation: 0 USDC</span>
                                <span className={"text-orange"}>Your purchased: 0 USDC</span>
                              </div>
                            }
                          </div>
                        </> :
                        currentStage === "Claims Open" ?
                        <>
                          <div className={"d-flex flex-column w-100 progressbar"}>
                            <div className={"d-flex justify-content-around text-white w-100 pb-2"}>
                              <span>Pool Purchased: { new BigNumber(poolData.totalUsdc.toString()).div(new BigNumber(1000000)).toFixed(2) } USDC / {new BigNumber(poolData.usdcAmount.toString()).div(new BigNumber(1000000)).toFixed(2)} USDC Max</span>
                            </div>
                            <div className={"w-100"}>
                              <ProgressBar percentage={new BigNumber(poolData.totalUsdc.toString()).times(new BigNumber(100)).div(new BigNumber(poolData.usdcAmount.toString())).toFixed(0)} singleUser={true} />
                            </div>
                            { allocation ?
                              <div className={"d-flex justify-content-between text-white w-100 pb-2"}>
                                <span>Your Allocation: {new BigNumber(allocationAmt).div(new BigNumber(1000000)).toFixed(3,1)} USDC</span>
                                <span className={"text-orange"}>Your purchased: {participant === null ? 0 : participantInfo ? new BigNumber(participantInfo.bought.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000)).toFixed(3,1) : 0} USDC</span>
                              </div> : 
                              <div className={"d-flex justify-content-between text-white w-100 pb-2"}>
                                <span>Your Allocation: 0 USDC</span>
                                <span className={"text-orange"}>Your purchased: 0 USDC</span>
                              </div>
                            }
                          </div>
                          { allocation && participantInfo.bought.gt(0) && !participantInfo.claimedUnlocked ?
                          <div className={"d-flex flex-column w-100 mt-4"}>
                            <Row className="align-items-center">
                              <Col className="col-3">
                                <div className="form-sample-text">You have</div>
                              </Col>
                              <Col className="col-6">
                                <div className="form-sample-text">{new BigNumber(participantInfo.initialUnlocked).div(new BigNumber(1000000)).toFixed(3,1)} {saleData.symbol} to claim</div>
                              </Col>
                              <Col className="col-2 d-flex">
                                <button className="vault-btn btn-orange mb-2" onClick={claimTokens}>
                                  Claim
                                </button>
                              </Col>
                            </Row>
                          </div> : <></> }
                          
                          { allocation && participantInfo.bought.gt(0) && (new BigNumber(vestedAmount)).gt(0) ?
                          <div className={"d-flex flex-column w-100 mt-4"}>
                            <Row className="align-items-center">
                              <Col className="col-3">
                                <div className="form-sample-text">You have</div>
                              </Col>
                              <Col className="col-6">
                                <div className="form-sample-text">{new BigNumber(vestedAmount).div(new BigNumber(1000000)).toFixed(3,1)} {saleData.symbol} vested</div>
                              </Col>
                              <Col className="col-2 d-flex">
                                <button className="vault-btn btn-orange mb-2" onClick={claimVestedTokens}>
                                  Claim
                                </button>
                              </Col>
                            </Row>
                          </div> : <></> }

                        </> : 
                        <></>}
                      </div>
                      <div className="col-3 d-flex justify-content-center">
                        <img src={saleData.image} />
                      </div>
                    </div>
                    <div className="row w-100 mt-5">
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column">
                        <HeadingDotted title="Pool Information" />
                        <div className="mt-5">
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row pt-2"}>
                                <span>Hydrazine Burn Opens</span>
                                <span>{poolData ? poolData.idoTimes ? moment.unix(poolData.idoTimes.registrationStart.toString()).utc().format("MMMM DD YYYY, h:mm:ss A") + " UTC" : "" : ""}</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Hydrazine Burn Closes</span>
                                <span>{poolData ? poolData.idoTimes ? moment.unix(poolData.idoTimes.registrationEnd.toString()).utc().format("MMMM DD YYYY, h:mm:ss A") + " UTC" : "" : ""}</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Whitelist Sale Opens</span>
                                <span>{poolData ? poolData.idoTimes ? moment.unix(poolData.idoTimes.saleStart.toString()).utc().format("MMMM DD YYYY, h:mm:ss A") + " UTC" : "" : ""}</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Whitelist Sale Closes</span>
                                <span>{poolData ? poolData.idoTimes ? moment.unix(poolData.idoTimes.saleEnd.toString()).utc().format("MMMM DD YYYY, h:mm:ss A") + " UTC" : "" : ""}</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>FCFS Sale Opens</span>
                                <span>{poolData ? poolData.idoTimes ? moment.unix(poolData.idoTimes.openSaleStart.toString()).utc().format("MMMM DD YYYY, h:mm:ss A") + " UTC" : "" : ""}</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Swap Rate</span>
                                <span>{poolData ? "1 USDC : " + new BigNumber(1000000).div(new BigNumber(poolData.price.toString())).toFixed(2) + " " + saleData.symbol : ""}</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Pool Size</span>
                                <span>{poolData ? new BigNumber(poolData.tokensForSale.toString()).div(new BigNumber(1000000)).toFixed(0) + " " + saleData.symbol : "" }</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Max Hydrazine</span>
                                <span>{poolData ? new BigNumber(poolData.maxHydrazine.toString()).div(new BigNumber(1000000)).toFixed(0) + " N2H4" : "" }</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>FCFS Cap</span>
                                <span>{poolData ? new BigNumber(poolData.openMaxAlloc.toString()).div(new BigNumber(1000000)).toFixed(0) + " " + saleData.symbol + " / " + new BigNumber(poolData.openMaxAlloc.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000)).toFixed(0) + " USDC" : "" }</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Initial Unlock</span>
                                <span>{poolData ? poolData.unlocked.toString() + "%" : ""}</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          {poolData ? poolData.vested ?
                          <>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span># of vested tranches</span>
                                <span>{poolData.tranches.toString()}</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Vesting Start Date</span>
                                <span>{ moment.unix(poolData.idoTimes.vestingStart.toString()).utc().format("MMMM DD YYYY, h:mm:ss A") + " UTC" }</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Tranche duration</span>
                                <span>{ new BigNumber(moment.duration(poolData.trancheLength.toNumber()).asDays()).times(new BigNumber(1000)).toFixed(2) + " days" }</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          </> : <></> : <></> }
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column">
                        <div className="col-12">
                          <HeadingDotted title="Token Information" />
                         <div className="mt-5">
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row pt-2"}>
                                <span>Name</span>
                                <span>{saleData.name}</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Symbol</span>
                                <span>{saleData.symbol}</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Address</span>
                                <span>{poolData ? <a target="_blank" href={"https://solscan.io/token/"+poolData.token.toBase58()}>Solscan</a> : ""}</span> 
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Supply</span>
                                <span>{saleData.supply}</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                          <SqaureBraket classes="mb-3">
                            <StatusBarTop
                              percentage="0"
                            >
                              <div className={"d-flex w-100 justify-content-between pool-row  pt-2"}>
                                <span>Launch Mcap</span>
                                <span>{"$" + saleData.launchCap}</span>
                              </div>
                            </StatusBarTop>
                          </SqaureBraket>
                        </div>
                        </div>
                    </div>
                    </div>
                  </>
                }
                </div>
            </div>
        </div>
      </div>
    </main>
  );
}

export default Ido;
