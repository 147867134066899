import "./index.css";

function HeadingDotted(props) {
  return (
    <div className="heading-dotted-container p-1">
      <div className={`${props.classes} heading-text`}>{props.title}</div>
      <div className="dotted-border"></div>
    </div>
  );
}

export default HeadingDotted;
