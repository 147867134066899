import "./index.css";

function Heading(props) {
  return (
    <div className="heading-container px-2">
      <span className="heading-text">{props.title}</span>
    </div>
  );
}

export default Heading;
