import { Fragment } from "react";
import ProgressBar from "../ProgressBar";
import "./index.css";

function Profile(props) {
  const { currentMP, totalMP, level } = props;
  const percentage = (currentMP / totalMP) * 100;
  return (
    <Fragment>
      <div className={props.className}>
        <div className="title-container d-flex justify-content-between text-uppercase mt-4">
          <span className="title-text">Cadet ID</span>
          <span className="title-text">{props.id}</span>
        </div>
        <div className="rank-container d-flex justify-content-between  text-uppercase p-2 pl-2">
          <div className="title d-flex flex-column">
            <span className="rank">Rank</span>
            <span className="cadet">{props.rank}</span>
          </div>
          <div>
            {`${currentMP}/${totalMP}`}
            <span>{` MP`}</span>
          </div>
          <div>{`LVL. ${level}`}</div>
        </div>
        <ProgressBar percentage={percentage} singleUser={true} />
      </div>
    </Fragment>
  );
}

export default Profile;
