import "./index.css";

function SqaureBraket(props) {
  return (
    <div className={`bracket d-flex ${props.classes}`}>
      <div className={"bracket-pre"}></div>
      <div className={"content w-100"}>{props.children}</div>
      <div className={"bracket-post"}></div>
    </div>
  );
}

export default SqaureBraket;
