import React, { Fragment, useState } from "react";
import "./index.css";
import {Button, Modal, Form, InputGroup, } from 'react-bootstrap';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
  Link
} from "react-router-dom";
import ProgressBar from "../ProgressBar";

function ShortIDOCard(props) {
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <div className="d-flex col-sm-12 col-md-6 col-lg-4 col-xl-3 justify-content-center">
        <div className="vault-container card-container vault-content m-2 p-3">
          <div className={"vault-tr-border-tr"} />
          <div className={"vault-tr-border-bl"} />
          <div className={"vault-tr-border-br"} />
          <div className={"vault-details d-flex flex-column align-items-center m-3 p-2 w-100 h-100"}>
            <div className={"d-flex vault-name w-100"}>
              <div className={"d-flex flex-row w-100 h-100"}>{props.name}</div>
              <div className={"d-flex flex-row justify-content-end mr-2"}>
                <img src={require('../../images/idoicon.png').default} />
              </div>
            </div>
            <div className={"d-flex flex-column vault-stats"}>
              <div className={"d-flex flex-row align-items-center w-100 h-100"}>
                <div>Info Line:</div>
                <div>
                  <span>DATE</span>
                  <span className={"text-blue m-3"}>$TICKER</span>
                </div>
              </div>
            </div>
            <div className={"d-flex"}>
              <button className="vault-btn btn-orange border-0 m-1" onClick={() => props.onBtnClick()}>{props.btnText}</button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="vault-modal"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Body className={"vault-create-modal"}>
          <div className={"vault-tr-border-tr"} />
          <div className={"vault-tr-border-bl"} />
          {
            !showMessage ?
              <>
              <div className={"vault-modal-exit"} >
                <button className={"vault-btn"} onClick={handleClose}>Exit</button>
              </div>
              <div className={"vault-form-title d-flex m-3"} >Vault Name</div>
              <Form className={"vault-form"}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={"text-blue"}>Stars Staked</Form.Label>
                   <InputGroup className="mb-3 ig-blue">
                    <Form.Control  value="32,512.3" />
                    <InputGroup.Text style={{ fontColor: 'blue' }}>$STARS</InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className={"text-orange"}>HYDRAZINE TO CLAIM</Form.Label>
                  <InputGroup className="mb-3 ig-orange">
                    <Form.Control  value="32,512.3" />
                    <InputGroup.Text className={"text-orange"}>$N2H4</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <div className={"vault-form-error pt-3 pb-3"}>ERROR MESSAGE! ERROR MESSAGE! ERROR MESSAGE! </div>
                <div className={"d-flex btn"}>
                  <button className={"vault-btn btn-orange border-0"} type="submit" onClick={setShowMessage}>
                    Confirm
                  </button>
                </div>
                <div className={"vault-form-error pt-3 pb-3"}>ERROR MESSAGE! ERROR MESSAGE! ERROR MESSAGE! </div>
                <div className={"d-flex btn"}>
                  <button className={"vault-btn btn-blue border-0"} type="submit">
                    Force unlock & Unstake
                  </button>
                </div>
                <div className={"vault-form-subtext"}>(if you have chosen the option to lock for 3 months when you created the vault box)</div>
              </Form>
              </>
            :
              <>
                <div className={"vault-modal-exit"} >
                  <button className={"vault-btn"} onClick={handleClose}>Abandon</button>
                </div>
                <Form className={"vault-form vault-form-message"}>
                  <div className={"message-title"}>Warning!!!</div>
                  <div className={"message-text"}>BY UNSTAKING EARLY YOU WILL INCUR 50% TOKEN DECAY</div>
                  <Form.Group controlId="formBasicCheckBox">
                    <Form.Check className={"d-flex"} name="formBasicCheckBox" type="checkbox" label="I FULLY UNDERSTAND THAT UNSTAKING BY FORCE WILL INCUR 50% TOKEN DECAY AND WISH TO PROCCED" />
                  </Form.Group>
                  <div className={"d-flex btn"}>
                    <button className={"vault-btn btn-blue border-0"} type="submit">
                      CONFIRM Force unlock & Unstake
                    </button>
                  </div>
                </Form>
              </>
          }
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

function IDOCard(props) {
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
        <div className="vault-container card-container vault-content">
          <div className={"vault-tr-border-tr"} />
          <div className={"vault-tr-border-bl"} />
          <div className={"vault-tr-border-br"} />
          <div className={"vault-details d-flex flex-column align-items-center m-3 p-2 w-100 h-100"}>
            <div className={"d-flex vault-name w-100"}>
              <div className={"d-flex flex-column w-100 h-100"}>
                <div className={"pb-2 mb-2 card-title w-90"}>{props.data.name}</div>
                <div className={"d-flex flex-row justify-content-start"}>
                  {props.data.telegram !== "" ? <a target="_blank" href={props.data.telegram}><img className={"social-icons"} src={require("../../images/telegram.png").default} /></a> : "" }
                  <a target="_blank" href={props.data.medium}><img className={"social-icons"} src={require("../../images/medium.png").default} /></a>
                  {props.data.discord !== "" ? <a target="_blank" href={props.data.discord}><img className={"social-icons"} src={require("../../images/discord.png").default} /></a> : "" }
                  <a target="_blank" href={props.data.twitter}><img className={"social-icons"} src={require("../../images/twitter.png").default} /></a>
                  <a target="_blank" href={props.data.website}><img className={"social-icons"} src={require("../../images/website.png").default} /></a>
                </div>
              </div>
              <div className={"d-flex flex-row justify-content-end"}>
                <img className={"card-icon"} src={props.data.icon} />
              </div>
            </div>
            <div className={"d-flex flex-column status w-100 pt-3 pb-3"}>
              <div className={"d-flex flex-row"}>
                <div>Status: {props.data.status}</div>
                <div className={"d-flex"}>
                  <span>Pair </span> <span className={"text-blue mr-3"}> ${props.data.pair}</span>
                </div>
              </div>
            </div>
            <div className={"d-flex text-center"}>
              <p>{props.data.description}</p>
            </div>
            {/*<div className={"d-flex flex-column w-100 progressbar"}>
              <div className={"d-flex justify-content-between text-white w-100 pb-2"}>
                <span>IDO PROGRESS</span>
                <span className={"text-orange"}>32%</span>
              </div>
              <div className={"w-100"}>
                <ProgressBar percentage={32} singleUser={true} />
              </div>
            </div>*/}
            <div className={"d-flex flex-column text-center stats w-100"}>
              <span>SWAP RATE - 1 {props.data.pair}:{props.data.swaprate} {props.data.symbol}</span>
              <span>POOL CAP - {props.data.poolcap} {props.data.symbol}</span>
              <span>AMOUNT - ${props.data.dollarAmount}</span>
              <span>ACCESS - PRIVATE</span>
              <span>PARTICIPANTS - {props.data.participants}</span>
            </div>
            {/*<div className={"d-flex mt-3"}>
              <button className="vault-btn m-1" onClick={() => props.onBtnClick()}>{props.btnText}</button>
            </div>*/}
          </div>
        </div>
    </Fragment>
  );
}

export { ShortIDOCard, IDOCard };
