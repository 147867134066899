import "./index.css";
import React, { useState } from "react";
import { useLocation } from "react-router";
import {
  Link
} from "react-router-dom";

import { Modal, Button } from "react-bootstrap";

function NavContent () {
  const location = useLocation();

  const [activeId, setActiveId] = useState(0);

  const toggleClass = (id) => {
    setActiveId(id);
  };

  return(<ul
            id="collapseExample1"
            className="collapse show list-group list-group-flush list-group-mine"
          >
          <Link to="/">
            <li onClick={() => toggleClass(0)} className={`list-group-item ${location.pathname === "/" ? "active" : ""}`} >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.02 2.84016L3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016Z"
                    stroke="#8A8B8C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 17.9902V14.9902"
                    stroke="#8A8B8C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
              </svg>
              <span className="d-block d-sm-none item-text">Home</span>
            </li>
            </Link>
            <Link to="/vault">
              <li disabled onClick={() => toggleClass(1)} className={`list-group-item ${location.pathname === "/vault" ? "active" : ""}`}>
                <div id="overlay">
                  <img width="28px" src={require("../../images/vault-icon.png").default} />
                </div>
                <span className="d-block d-sm-none item-text">Vault</span>
              </li>
            </Link>
            <Link to="/sales">
              <li onClick={() => toggleClass(2)} className={`list-group-item ${location.pathname === "/sales" || location.pathname === "/sale-detail" ? "active" : ""}`}>
                <div id="overlay">
                  <img width="28px" src={require("../../images/sales.png").default} />
                </div>
                  <span className="d-block d-sm-none item-text">IDOs</span>
              </li>
            </Link>
            <Link to="/claims">
              <li onClick={() => toggleClass(2)} className={`list-group-item ${location.pathname === "/claims" ? "active" : ""}`}>
                <div id="overlay">
                  <img width="28px" src={require("../../images/claims-icon.png").default} />
                </div>
                  <span className="d-block d-sm-none item-text">Claims</span>
              </li>
            </Link>
            {/*
              <li  onClick={() => toggleClass(0)} className={`list-group-item ${activeId === 3 ? "active" : ""}`}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                      stroke="#8A8B8C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
                      stroke="#8A8B8C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.66992 18.9501L7.59992 15.6401C8.38992 15.1101 9.52992 15.1701 10.2399 15.7801L10.5699 16.0701C11.3499 16.7401 12.6099 16.7401 13.3899 16.0701L17.5499 12.5001C18.3299 11.8301 19.5899 11.8301 20.3699 12.5001L21.9999 13.9001"
                      stroke="#8A8B8C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>IDOs</span>
              </li>
              <li onClick={() => toggleClass(0)} className={`list-group-item ${activeId === 4 ? "active" : ""}`}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 17.5H14"
                      stroke="#8A8B8C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 17.5V7.5C2 3.5 3 2.5 7 2.5"
                      stroke="#8A8B8C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 17.5V7.5C22 3.5 21 2.5 17 2.5"
                      stroke="#8A8B8C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 15.9099V19.1999C10 21.1999 9.2 21.9999 7.2 21.9999H4.8C2.8 21.9999 2 21.1999 2 19.1999V15.9099C2 13.9099 2.8 13.1099 4.8 13.1099H7.2C9.2 13.1099 10 13.9099 10 15.9099Z"
                      stroke="#8A8B8C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 15.9099V19.1999C22 21.1999 21.2 21.9999 19.2 21.9999H16.8C14.8 21.9999 14 21.1999 14 19.1999V15.9099C14 13.9099 14.8 13.1099 16.8 13.1099H19.2C21.2 13.1099 22 13.9099 22 15.9099Z"
                      stroke="#8A8B8C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Home</span>
              </li>
              <li onClick={() => toggleClass(0)} className={`list-group-item ${activeId === 5 ? "active" : ""}`}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.3601 3.64014C17.6185 4.89893 18.4754 6.50258 18.8224 8.24833C19.1694 9.99408 18.991 11.8035 18.3098 13.4479C17.6285 15.0923 16.4749 16.4977 14.9949 17.4865C13.515 18.4753 11.775 19.0031 9.99512 19.0031C8.21521 19.0031 6.47527 18.4753 4.99529 17.4865C3.51532 16.4977 2.36176 15.0923 1.68049 13.4479C0.999212 11.8035 0.82081 9.99408 1.16784 8.24833C1.51487 6.50258 2.37174 4.89893 3.63012 3.64014"
                      stroke="#8A8B8C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 7V1"
                      stroke="#8A8B8C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Home</span>
              </li>
            */}
          </ul>)
}

function Leftnav() {
  const [show, setShow] = useState(false);

  const handleToggle = () => {
    setShow(!show);
  };

  return (
    <div className="sidebar" onClick={e => e.stopPropagation()}>
      <div>
        <i className="fa fa-bars" onClick={handleToggle}/>
      </div>
    <nav
      id="sidebarMenu"
      className="d-none d-sm-block sidebar sidebar-menu"
      >
      <div className="d-block d-md-none">
        <Modal 
            show={show} 
            onHide={() => setShow(false)}
            className="sidebar-modal"
          >
            <Modal.Header>
              Star Launch
            </Modal.Header>
            <Modal.Body>
              <NavContent />
            </Modal.Body> 
        </Modal>
      </div>
      <div className="position-sticky">
        <div className="list-group list-group-flush ">
          <NavContent />
        </div>
      </div>
    </nav>
    </div>
  );
}

export default Leftnav;
