import "./index.css";

function ProgressBar(props) {
  const { singleUser, percentage } = props;
  return (
    <div className={`progress w-100 ${singleUser ? "single" : ""}`}>
      <div
        className={`progress-bar ${singleUser ? "single" : ""}`}
        role="progressbar"
        style={{ width: `${percentage}%` }}
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
}

export default ProgressBar;
