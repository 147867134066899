import React, { Fragment, useState, useEffect } from "react";
import "./index.css";
import {Button, Modal, Form, InputGroup} from 'react-bootstrap';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
  Link
} from "react-router-dom";
import BigNumber from "bignumber.js";
import moment from 'moment';


function CreateVault(props) {
  const [show, setShow] = useState(false);
  const [boxId, setBoxId] = useState(null);
  const [boxAmount, setBoxAmount] = useState(0);
  const [lockState, setLockState] = useState(null);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [error2, setError2] = useState(false);
  const [errorText2, setErrorText2] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => {
    console.log('Show Vault')
    setShow(true);
  }

  useEffect(()=>{
    console.log(props.boxId);
    setBoxId(props.boxId);
  },[props.boxId]);

  function updateBoxAmount(e) {
    if (e.target.value > 0) {
      setError2(0);
    }
    setBoxAmount(e.target.value);
  }

  function updateLockState(e) {
    if (e.target.value === "1")  {
      setError(true);
      setErrorText("Your STARS in the box will decay at ~0.767% per day");
      setError2(false);
    } else if (e.target.value === "2") {
      setError(true);
      setErrorText("Your STARS in the box will be locked for 90 days. Unlocking early shall involve a penalty");
      setError2(false);
    }
    setLockState(e.target.value);
  }

  function makeVault() {
    console.log(lockState);
    if (boxAmount <= 0) {
      setError2(true);
      setErrorText2("Amount should be >0");
    } else if (lockState !== "1" && lockState !=="2") {
      setError2(true);
      setErrorText2("Lock state of the box not chosen");
    } else {
      props.createVaultAction(boxAmount, lockState);
    }
  }

  return (
    <Fragment>
      <div className="col-md-6 col-lg-4 d-flex">
        <div className="vault-container d-flex">
          <div className="vault-content">
            <div className={"vault-tr-border-tr"} />
            <div className={"vault-tr-border-bl"} />
            <div className={"vault-tr-border-tl"} />
            <div className={"d-flex flex-column align-items-center m-2"} onClick={handleShow}>
              <img className={"vault-plus"} src={require("../../images/vault-plus.png").default} />
              <button className="vault-btn" >Create Vault Box</button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {setShow(false); setError(false); setError2(false);}}
        dialogClassName="vault-modal"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Body className={"vault-create-modal"}>
          <div className={"vault-tr-border-tr"} />
          <div className={"vault-tr-border-bl"} />
          <div className={"vault-modal-exit"} >
            <button className={"vault-btn"} onClick={handleClose}>Exit</button>
          </div>
          <div className={"vault-form-title d-flex m-3"} >Create Vault Box</div>
          <Form className={"vault-form"}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className={"text-orange"}>Vault ID</Form.Label>
              <Form.Control type="text" placeholder="Enter Vault Name" readOnly defaultValue={boxId} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              {/* <div className={"d-flex flex-row justify-content-between"}> */}
                <Form.Label className={"text-blue"}>stake amount</Form.Label>
                {/* <Form.Label className={"text-white"}>max</Form.Label> */}
              {/* </div> */}
              <Form.Control type="number" placeholder="Enter Stake Amount" value={boxAmount} onChange={updateBoxAmount}/>
            </Form.Group>
            <Form.Group controlId="formBasicRadio">
              <Form.Check className={"d-flex"} name="lockState" onChange={updateLockState} value="1" type="radio" label="Stake without lock (decays stars 0.767% per day)" />
              <Form.Check className={"d-flex"} name="lockState" onChange={updateLockState} value="2" type="radio" label="Stake WITH 90 DAY lock" />
            </Form.Group>
            {error ? 
            <div className={"vault-form-error pt-3 pb-3"}>{errorText}</div>:
            <></>
            }
            {error2 ? 
            <div className={"vault-form-error pt-3 pb-3"}>{errorText2}</div>:
            <></>
            }
          </Form>
          <div className={"d-flex btn"}>
            <button className={"vault-btn btn-orange border-0"} onClick={makeVault} style={{margin:"auto", marginBottom: "10px"}}>
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

function DisplayVault(props) {
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [error1, setError1] = useState(false);
  const [error1Text, setError1Text] = useState("");
  const [error2, setError2] = useState(false);
  const [error2Text, setError2Text] = useState("");
  const [agreeCheck, setAgreeCheck] = useState(false);
  console.log(props.vaultBox.locked);

  const handleClose = () => {
    setShow(false);
    setShowMessage(false);
  }
  const handleShow = () => setShow(true);

  function claimBox() {
    props.claimVaultBox(props.vaultBox);
  }

  function closeBox() {
    props.closeBox(props.vaultBox);
  }

  function forceCloseBox() {
    if (agreeCheck || new BigNumber(props.vaultBox.account.unstakePenalty).lte(0)) {
      props.closeBox(props.vaultBox);
    }
  }

  function unstakeAgree(e) {
    setAgreeCheck(e.target.checked);
  }

  return (
    <Fragment>
      <div className="col-md-6 col-lg-4 d-flex">
        <div className="vault-container m-2">
          <div className="vault-content">
            <div className={"vault-tr-border-tr"} />
            <div className={"vault-tr-border-bl"} />
            <div className={"vault-tr-border-br"} />
            <div className={"vault-details d-flex flex-column align-items-center m-3 p-2 w-100 h-100"}>
              <div className={"d-flex vault-name w-90 justify-content-between"}>
                <div className={"d-flex flex-row align-items-center h-100"}>{"Box " + String(props.vaultBox.account.ownerBoxId.toNumber()).padStart(5, '0')}</div>
                {
                  props.vaultBox.account.locked ?
                  <div className={"d-flex flex-row align-items-center h-100 font-m"}>
                    <i className="fas fa-lock p-2"></i> LOCKED
                  </div>
                  :
                  <div className={"d-flex flex-row align-items-center h-100 font-m"}>
                     <i className="fas fa-lock-open p-2"></i>  UNLOCKED
                  </div>  
                }
              </div>
              <div className={"d-flex flex-column vault-stats w-90"}>
                <div className={"d-flex flex-row align-items-center w-100 h-100"}>
                  <div>staked:</div>
                  <div>
                    <span>{new BigNumber(props.vaultBox.account.curStaked).toFixed(4)}</span>
                    <span className={"text-blue"}>$STARS</span>
                  </div>
                </div>
                <div className={"vault-divider"} />
                <div className={"d-flex flex-row align-items-center w-100 h-100"}>
                  <div>to claim:</div>
                  <div>
                    <span>{new BigNumber(props.vaultBox.account.pendingHydrazine).toFixed(4)}</span>
                    <span className={"text-orange"}>$N2H4</span>
                  </div>
                </div>
              </div>
              <div className={"d-flex"}>
                <button className="vault-btn m-1" onClick={handleShow}>Enter Vault</button>
                <button className="vault-btn btn-orange border-0 m-1" onClick={claimBox}>Claim</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {setShow(false); setShowMessage(false);}}
        dialogClassName="vault-modal"
        aria-labelledby="Vault Box Info Display"
        centered
      >
        <Modal.Body className={"vault-create-modal"}>
          <div className={"vault-tr-border-tr"} />
          <div className={"vault-tr-border-bl"} />
          {
            !showMessage ?
              <>
              <div className={"d-flex flex-row w-100 text-white justify-content-between mr-4 vault-modal-exit"} >
                <button className={"vault-btn"} onClick={handleClose}>Exit</button>
                {
                  props.vaultBox.account.locked ?
                  <div className={"d-flex flex-row align-items-center h-100 font-m locked"}>
                    <i class="fas fa-lock p-2 pr-4"></i> LOCKED until {moment.unix(props.vaultBox.account.unlockTime).format("MMMM DD YYYY, h:mm:ss A")}
                  </div>
                  :
                  <div className={"d-flex flex-row align-items-center h-100 font-m locked"}>
                     <i classNamef="fas fa-lock-open p-2"></i>  UNLOCKED
                  </div>  
                }
              </div>
              <div className={"vault-form-title d-flex m-3"} >{"Box " + String(props.vaultBox.account.ownerBoxId.toNumber()).padStart(5, '0')}</div>
              <Form className={"vault-form"}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={"text-blue"}>Stars Staked</Form.Label>
                   <InputGroup className="mb-3 ig-blue">
                    <Form.Control  value={new BigNumber(props.vaultBox.account.curStaked).toFixed(4)} />
                    <InputGroup.Text style={{ fontColor: 'blue' }}>$STARS</InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className={"text-orange"}>HYDRAZINE TO CLAIM</Form.Label>
                  <InputGroup className="mb-3 ig-orange">
                    <Form.Control  value={new BigNumber(props.vaultBox.account.pendingHydrazine).toFixed(4)} />
                    <InputGroup.Text className={"text-orange"}>$N2H4</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                {error1 ? 
                <div className={"vault-form-error pt-3 pb-3"}>{ error1Text }</div>
                :
                <></>
                }
                {error2 ? 
                <div className={"vault-form-error pt-3 pb-3"}>{ error2Text }</div>
                :
                <></>
                }
                {/* <div className={"d-flex btn"}>
                  <button className={"vault-btn btn-orange border-0"} type="submit" onClick={setShowMessage}>
                    Confirm
                  </button>
                </div> */}
                </Form>
                <div className={"d-flex btn"}>
                    {props.vaultBox.account.locked ?
                      <button className={"vault-btn btn-orange border-0"} onClick={setShowMessage} style={{margin:"auto", marginBottom: "20px"}}>
                        Force Unstake and Claim
                      </button> :  
                      <button className={"vault-btn btn-blue border-0"} onClick={closeBox} style={{margin:"auto", marginBottom: "20px"}}>
                        Unstake and Claim
                      </button>
                    }
                </div>
                {/* <div className={"vault-form-subtext"}>(if you have chosen the option to lock for 3 months when you created the vault box)</div> */}
              </>
            :
              <>
                <div className={"vault-modal-exit"} >
                  <button className={"vault-btn"} onClick={handleClose}>Abandon</button>
                </div>
                <Form className={"vault-form vault-form-message"}>
                  <div className={"message-title"}>Warning!!!</div>
                  <div className={"message-text"}>BY UNSTAKING NOW YOU WILL LOSE { new BigNumber(props.vaultBox.account.unstakePenalty).gt(0) ? "~"+ new BigNumber(props.vaultBox.account.unstakePenalty).toFixed(4) : 0 } STARS AS PENALTY. REFRESH THE PAGE FOR UP TO DATE PENALTY.</div>
                  { new BigNumber(props.vaultBox.account.unstakePenalty).gt(0) ?
                    <Form.Group controlId="formBasicCheckBox">
                      <Form.Check className={"d-flex"} name="unstake-agree" onChange={unstakeAgree} type="checkbox" label={"I FULLY UNDERSTAND THAT FORCE UNSTAKING WILL INCUR ~"+ new BigNumber(props.vaultBox.account.unstakePenalty).toFixed(4) + " STARS AS PENALTY AND WISH TO PROCCED"} />
                    </Form.Group> :
                    <></>
                  }
                </Form>
                <div className={"d-flex btn"}>
                  <button className={"vault-btn btn-orange border-0"} onClick={forceCloseBox} style={{margin:"auto", marginTop: "20px", marginBottom: "4rem"}}>
                    CONFIRM Force Unstake & Claim
                  </button>
                </div>
              </>
          }
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

function ConnectWallet(props) {

  return (
    <Fragment>
      <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 vault-container m-2 p-3 d-flex">
        <div className="vault-content">
          <div className={"vault-tr-border-tr"} />
          <div className={"vault-tr-border-bl"} />
          <div className={"vault-tr-border-tl"} />
          <div className={"d-flex flex-column align-items-center m-2"}>
            <WalletMultiButton />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function CreateAccount(props) {
  return (
    <Fragment>
      <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 vault-container m-2 p-3 d-flex">
        <div className="vault-content">
          <div className={"vault-tr-border-tr"} />
          <div className={"vault-tr-border-bl"} />
          <div className={"vault-tr-border-tl"} />
          <div className={"d-flex flex-column align-items-center m-2"} onClick={props.createAccountAction}>
            <img className={"vault-plus"} src={require("../../images/vault-plus.png").default} />
            <button className="vault-btn" >Create Account</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function MakeCadet(props) {
  return (
    <Fragment>
      <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 vault-container m-2 p-3 d-flex">
        <div className="vault-content">
          <div className={"vault-tr-border-tr"} />
          <div className={"vault-tr-border-bl"} />
          <div className={"vault-tr-border-tl"} />
          <div className={"d-flex flex-column align-items-center m-2"} onClick={props.createAccountAction}>
            <Link className="vault-btn" to="/">Please make a Cadet Account</Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export { CreateVault, DisplayVault, ConnectWallet, CreateAccount, MakeCadet };
