import React, {Fragment, useState, useEffect} from 'react';
import BigNumber from 'bignumber.js';
import Decimal from 'decimal.js-light';
import moment from 'moment';

import { Program, Provider, web3, BN, utils } from '@project-serum/anchor';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import toast from 'react-hot-toast';
import { useAnchorWallet, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { API_URL, starsAdd, hydrazineAdd, cadetProgramAdd, vaultProgramAdd } from '../../constants/constants';

import cadetIdl from '../../web3/idl/cadet.json';
import vaultIdl from '../../web3/idl/vault.json';

import "./index.css";
import { CreateVault, DisplayVault, ConnectWallet, CreateAccount, MakeCadet } from "../../Components/Vault";

function Vault() {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { SystemProgram, SYSVAR_RENT_PUBKEY } = web3;
  // const starsMint = new web3.PublicKey("FofXjmyFpNKGPUP9SJQk61zXe1zQAQEUo2BA7UT1CFFS");
  // const hydrazineMint = new web3.PublicKey("7MdRtgeHEYqfTattjttSEHkcdJP7kzKeWhBQZSqoNRVX");
  const starsMint = new web3.PublicKey(starsAdd);
  const hydrazineMint = new web3.PublicKey(hydrazineAdd);
  const vaultProgramName = "star_vault";
  const [provider, setProvider] = useState(null);
  const cadetProgramID = new web3.PublicKey(cadetProgramAdd);
  const vaultProgramID = new web3.PublicKey(vaultProgramAdd);
  const [walletData, setWalletData] = useState('fetching');
  const [cadetAccount, setCadetAccount] = useState(null);
  const [vaultAccount, setVaultAccount] = useState(null);
  const [mainVault, setMainVault] = useState(null);
  const [newBoxId, setNewBoxId] = useState(null);
  const [vaultBoxes, setVaultBoxes] = useState([]);
  const [totalStaked, setTotalStaked] = useState("");
  const [totalDecayed, setTotalDecayed] = useState("");
  const [totalPenalized, setTotalPenalized] = useState("");
  const DEFAULT_TIMEOUT = 31000;
  useEffect(()=>{
    if (wallet.publicKey) {
        console.log(wallet.publicKey.toBase58());
        setWalletData('connected');
        setProvider(new Provider(connection,wallet,Provider.defaultOptions()));
        // let message = new TextEncoder().encode("Hello boy");
        // wallet.signMessage(message);
        // await getAccountData();
    } else {
      setWalletData('not connected');
    }
  }, [wallet.publicKey]);

  useEffect(() => {
    if (provider) {
      getAccountData();
    }
  },[provider]);

  async function getAddys() {
    const [mainVault, mainVaultBump] = await web3.PublicKey.findProgramAddress(
      [Buffer.from(vaultProgramName)],
      vaultProgramID
    );
    const [cadetAcct, cadetAcctBump] = await web3.PublicKey.findProgramAddress(
      [provider.wallet.publicKey.toBuffer(),Buffer.from("Cadet_Reg"),Buffer.from("register_cadet")],
      cadetProgramID
    );
    const [vaultAccount, vaultAccountBump] = await web3.PublicKey.findProgramAddress(
      [provider.wallet.publicKey.toBuffer(), Buffer.from(vaultProgramName), Buffer.from("vault_account")],
      vaultProgramID
    );
    return {mainVault: mainVault, cadetAcct: cadetAcct,
            vaultAccount: vaultAccount, mainVaultBump: mainVaultBump,
            cadetAcctBump: cadetAcctBump, vaultAccountBump: vaultAccountBump}
  }
  
  async function findATA(wallet,mint) {
    const ata = await web3.PublicKey.findProgramAddress([wallet.toBuffer(),TOKEN_PROGRAM_ID.toBuffer(),mint.toBuffer()],
                    ASSOCIATED_TOKEN_PROGRAM_ID);
    console.log(ata[0]);
    return ata[0];
  }

  async function getAccountData() {
    const accts = await getAddys();
    console.log(accts.vaultAccount.toBase58());
    const vaultProgram = new Program(vaultIdl, vaultProgramID, provider);
    const mainVaultNow = await vaultProgram.account.mainVault.fetch(accts.mainVault);
    setMainVault(mainVaultNow);
    setTotalStaked(new BigNumber(mainVaultNow.totalStaked.toString()).div(new BigNumber(1000000)).toFixed(2));
    setTotalDecayed(new BigNumber(mainVaultNow.totalDecay.toString()).div(new BigNumber(1000000)).toFixed(2));
    setTotalPenalized(new BigNumber(mainVaultNow.totalPenalty.toString()).div(new BigNumber(1000000)).toFixed(2));
    console.log(mainVaultNow.lockedStaked.toString());
    console.log(mainVaultNow.unlockedStaked.toString());
    let curTime = moment().unix();
    console.log(mainVaultNow);
    let toPay = new BigNumber(mainVaultNow.accHydrazinePerShare.toString());
    let lastGlobalPay = mainVaultNow.lastUpdateTime.toNumber();
    if (curTime < lastGlobalPay) {
      lastGlobalPay = curTime;
    }
    // calculate the overall decay (in actual decimals, not *10^6)
    let timelapse = new BigNumber(curTime - lastGlobalPay);
    let timeRatio = timelapse.div(new BigNumber(mainVaultNow.starsHalf.toString()));
    let leftTokens = new BigNumber(mainVaultNow.unlockedStaked.toString())
                      .times(
                          new BigNumber(new Decimal(0.5).pow(new Decimal(timeRatio.toString())).toString())
                        )
                      .plus(new BigNumber(1))
                      .div(new BigNumber(1000000));
    let starsToDecay;
    if (leftTokens.gte(new BigNumber(mainVaultNow.unlockedStaked).div(new BigNumber(1000000)))) {
      starsToDecay = new BigNumber(0);
    } else {
      starsToDecay = new BigNumber(mainVaultNow.unlockedStaked.toString())
                          .div(new BigNumber(1000000))
                          .minus(leftTokens);
    }
    mainVaultNow.curUnlockedStaked = new BigNumber(mainVaultNow.unlockedStaked.toString())
                                    .div(new BigNumber(1000000))
                                    .minus(starsToDecay)
                                    .toString();
    mainVaultNow.curTotalStaked = new BigNumber(mainVaultNow.totalStaked.toString())
                                      .div(new BigNumber(1000000))
                                      .minus(starsToDecay)
                                      .toString();
                        

    // calculate the overall new rewards per stars
    mainVaultNow.newAcc = new BigNumber(toPay.toString())
                        .plus(
                          new BigNumber((curTime - lastGlobalPay))
                          .times(new BigNumber(mainVaultNow.hydrazineRate.toString()))
                          .times(new BigNumber(100))
                          .div(new BigNumber(mainVaultNow.curTotalStaked))
                        ).toString();
    setMainVault(mainVaultNow);
    setTotalStaked(new BigNumber(mainVaultNow.curTotalStaked).toFixed(2));
    setTotalDecayed(new BigNumber(mainVaultNow.totalDecay.toString()).div(new BigNumber(1000000)).plus(starsToDecay).toFixed(2));
    const cadetAccountInit = await connection.getBalance(accts.cadetAcct);
    if (cadetAccountInit > 0) {
      setCadetAccount(accts.cadetAcct);
      const vaultAccountInit = await connection.getBalance(accts.vaultAccount);
      if (vaultAccountInit > 0) {
        setVaultAccount(accts.vaultAccount);
        // Get all the vaults for this user
        const boxOwner = await vaultProgram.account.boxOwner.fetch(accts.vaultAccount);
        console.log("Box " + String(boxOwner.totalBoxes.toNumber()).padStart(5, '0'));
        setNewBoxId("Box " + String(boxOwner.totalBoxes.toNumber()).padStart(5, '0'));
        let myBoxes = boxOwner.totalBoxes.toNumber();
        let vaults = [];
        for (let i=0; i<myBoxes; i++) {
          const [myBox, myBoxBump] = await web3.PublicKey.findProgramAddress(
            [provider.wallet.publicKey.toBuffer(), Buffer.from(vaultProgramName), new BN(i.toString()).toArrayLike(Buffer,'le',8),
            Buffer.from("vault_box")],
            vaultProgramID
          );
          const myBoxInit = await connection.getBalance(myBox);
          if (myBoxInit > 0) {
            let myVault = await vaultProgram.account.vaultBox.fetch(myBox);
            vaults.push({key:i, account: myVault, publicKey: myBox});
          } 
        }
        // let vaults = await vaultProgram.account.vaultBox.all();
        // let owners = await vaultProgram.account.boxOwner.all();
        // console.log(vaults.length);
        // console.log(owners.length);
        // vaults = vaults.filter(vault => vault.account.ownerWallet.toBase58() === provider.wallet.publicKey.toBase58());
        
        let userBoxes = [];
        

        // Run through the user vault boxes
        for (let i=0; i<vaults.length; i++) {
          // if (vaults[i].account.ownerWallet.toBase58() === provider.wallet.publicKey.toBase58()) {
            vaults[i].key = i;
            let lastUserPay = vaults[i].account.lastUpdateTime.toNumber();
            if (curTime < lastUserPay) {
              lastUserPay = curTime;
            }
            // calculate own decay (in actual decimals, not *10^6)
            vaults[i].account.curStaked = new BigNumber(vaults[i].account.staked.toString()).div(new BigNumber(1000000)).toString();
            if (!vaults[i].account.locked) {
              let boxTimelapse = new BigNumber(curTime - lastUserPay);
              let boxTimeRatio = boxTimelapse.div(new BigNumber(mainVaultNow.starsHalf.toString()));
              let boxLeftTokens = new BigNumber(vaults[i].account.curStaked)
                                .times(
                                  new BigNumber(new Decimal(0.5).pow(new Decimal(boxTimeRatio.toString())).toString())
                                  );
              let boxStarsToDecay = new BigNumber(vaults[i].account.curStaked)
                                    .minus(boxLeftTokens);
              vaults[i].account.curStaked = new BigNumber(vaults[i].account.curStaked)
                                            .minus(boxStarsToDecay).toString();
            }
            // calculate own pending rewards
            vaults[i].account.pendingHydrazine = new BigNumber(vaults[i].account.curStaked).times(
                                            new BigNumber(mainVaultNow.newAcc)
                                            .minus(new BigNumber(vaults[i].account.accHydrazinePerShare.toString()))
                                            ).div(new BigNumber(100000000)).toString();
            
            // calculate own penalty
            vaults[i].account.unstakePenalty = "0";
            if (vaults[i].account.locked) {
              // console.log(vaults[i].account.unlockTime.toString());
              let penalTimeLapse = new BigNumber(vaults[i].account.unlockTime.toNumber() - curTime);
              let penalTimeRatio = penalTimeLapse.div(new BigNumber(mainVaultNow.starsHalf.toString()));
              let penalLeftTokens = new BigNumber(vaults[i].account.curStaked)
                                    .times(
                                      new BigNumber(new Decimal(0.5).pow(new Decimal(penalTimeRatio.toString())).toString())
                                      );
              let penalTotalDecay = new BigNumber(vaults[i].account.curStaked).minus(penalLeftTokens);
              let penalDecay = penalTotalDecay.div(new BigNumber(2));
              vaults[i].account.unstakePenalty = penalDecay.toString();
            }

            // add to the final list
            userBoxes.push(vaults[i]);
          // }
        }
        console.log(userBoxes);
        // console.log(vaults);
        setVaultBoxes(userBoxes);
        console.log(userBoxes);
        console.log(newBoxId);
      } else {
        console.log("Vault account doesn't exist yet");
        setVaultAccount(null);
      }
    } else {
      console.log("Cadet Account doesn't exist yet");
      setCadetAccount(null);
    }
  }

  async function createVaultAccount() {
    try {
      const accts = await getAddys();
      const vaultProgram = new Program(vaultIdl, vaultProgramID, provider);
      const transaction = new web3.Transaction();
      transaction.add(
        vaultProgram.instruction.createAccount(accts.vaultAccountBump, {
          accounts: {
            userAuthority: provider.wallet.publicKey,
            boxOwner: accts.vaultAccount,
            mainVault: accts.mainVault,
            systemProgram: SystemProgram.programId,
            rent: SYSVAR_RENT_PUBKEY,
          },
        })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Vault Account Created",{
        duration: 3000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
        className: 'success-toast',
        icon: '✓',
      });
      window.location.reload();
      // setVaultAccount(accts.vaultAccount);
      // await getAccountData();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message,{
          duration: 2000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
          color: "#FFF"},
          className: 'error-toast',
          icon: '⚠',
      });
    }
  }

  async function createVault(amount, lock) {
    try {
      const accts = await getAddys();
      const vaultProgram = new Program(vaultIdl, vaultProgramID, provider);
      const boxOwner = await vaultProgram.account.boxOwner.fetch(accts.vaultAccount);
      const [vaultBox, vaultBoxBump] = await web3.PublicKey.findProgramAddress(
        [provider.wallet.publicKey.toBuffer(), Buffer.from(vaultProgramName), boxOwner.totalBoxes.toArrayLike(Buffer,'le',8),
        Buffer.from("vault_box")],
        vaultProgramID
      );
      const userStars = await findATA(provider.wallet.publicKey,starsMint);
      const [vaultStars, vaultStarsBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(vaultProgramName), Buffer.from("vault_stars")],
        vaultProgramID
      );
      const [vaultHydrazine, vaultHydrazineBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(vaultProgramName), Buffer.from("vault_hydrazine")],
        vaultProgramID
      );
      const transaction = new web3.Transaction();
      console.log(Number(Number(amount).toFixed(6)));
      console.log(lock);
      const vaultAmount = new BN(Number(Number(amount).toFixed(6))*1000000);
      const lockStatus = lock==="2" ? true : false;
      console.log(lockStatus);
      console.log(vaultAmount.toString());
      transaction.add(
        vaultProgram.instruction.createVault(vaultBoxBump,
          vaultAmount,
          lockStatus,
          {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              vaultBox: vaultBox,
              boxOwner: accts.vaultAccount,
              mainVault: accts.mainVault,
              starsMint: starsMint,
              hydrazineMint: hydrazineMint,
              userStars: userStars,
              vaultStars: vaultStars,
              vaultHydrazine: vaultHydrazine,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
              rent: SYSVAR_RENT_PUBKEY,
            },
        })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("New Vault Created",{
        duration: 3000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
        className: 'success-toast',
        icon: '✓',
      });
      window.location.reload();
      // setVaultAccount(accts.vaultAccount);
      // await getAccountData();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message,{
          duration: 2000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
          color: "#FFF"},
          className: 'error-toast',
          icon: '⚠',
      });
    }
  }

  async function claimVaultBox(vaultBoxInfo) {
    try {
      const accts = await getAddys();
      const vaultProgram = new Program(vaultIdl, vaultProgramID, provider);
      const boxOwner = await vaultProgram.account.boxOwner.fetch(accts.vaultAccount);
      // const [vaultBox, vaultBoxBump] = await web3.PublicKey.findProgramAddress(
      //   [provider.wallet.publicKey.toBuffer(), Buffer.from(vaultProgramName), boxOwner.totalBoxes.toArrayLike(Buffer,'le',8),
      //   Buffer.from("vault_box")],
      //   vaultProgramID
      // );
      const vaultBox = vaultBoxInfo.publicKey;
      console.log(vaultBoxInfo);
      const [vaultStars, vaultStarsBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(vaultProgramName), Buffer.from("vault_stars")],
        vaultProgramID
      );
      const userHydrazine = await findATA(provider.wallet.publicKey,hydrazineMint);
      const [vaultHydrazine, vaultHydrazineBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(vaultProgramName), Buffer.from("vault_hydrazine")],
        vaultProgramID
      );
      const hydrazineAcctBal = new BN(await connection.getBalance(userHydrazine)); 
      if (hydrazineAcctBal.isZero()) {
          let token = new Token(connection,hydrazineMint,TOKEN_PROGRAM_ID,provider.wallet.publicKey);
      //    await token.createAssociatedTokenAccount(provider.wallet.publicKey);
          let tx = new web3.Transaction();
          tx.add(
              Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID,TOKEN_PROGRAM_ID,hydrazineMint,
                      userHydrazine,provider.wallet.publicKey,provider.wallet.publicKey)
          );
          const sign = await provider.wallet.sendTransaction(tx,connection);
          await connection.confirmTransaction(sign,'processed');
          toast("Hydrazine token account created",{
              duration: 2000,
              position: 'bottom-left',
              style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
              className: 'success-toast',
              icon: '✓',
          });
      }
      const transaction = new web3.Transaction();
      transaction.add(
        vaultProgram.instruction.claim(
          {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              vaultBox: vaultBox,
              boxOwner: accts.vaultAccount,
              mainVault: accts.mainVault,
              starsMint: starsMint,
              hydrazineMint: hydrazineMint,
              vaultStars: vaultStars,
              userHydrazine: userHydrazine,
              vaultHydrazine: vaultHydrazine,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
            },
        })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Hydrazine Claimed",{
        duration: 3000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
        className: 'success-toast',
        icon: '✓',
      });
      window.location.reload();
      // setVaultAccount(accts.vaultAccount);
      // await getAccountData();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message,{
          duration: 2000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
          color: "#FFF"},
          className: 'error-toast',
          icon: '⚠',
      });
    }
  }

  async function closeBox(vaultBoxInfo) {
    try {
      const accts = await getAddys();
      const vaultProgram = new Program(vaultIdl, vaultProgramID, provider);
      const boxOwner = await vaultProgram.account.boxOwner.fetch(accts.vaultAccount);
      // const [vaultBox, vaultBoxBump] = await web3.PublicKey.findProgramAddress(
      //   [provider.wallet.publicKey.toBuffer(), Buffer.from(vaultProgramName), boxOwner.totalBoxes.toArrayLike(Buffer,'le',8),
      //   Buffer.from("vault_box")],
      //   vaultProgramID
      // );
      const vaultBox = vaultBoxInfo.publicKey;
      console.log(vaultBoxInfo);
      const userStars = await findATA(provider.wallet.publicKey,starsMint);
      const [vaultStars, vaultStarsBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(vaultProgramName), Buffer.from("vault_stars")],
        vaultProgramID
      );
      const userHydrazine = await findATA(provider.wallet.publicKey,hydrazineMint);
      const [vaultHydrazine, vaultHydrazineBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(vaultProgramName), Buffer.from("vault_hydrazine")],
        vaultProgramID
      );
      const hydrazineAcctBal = new BN(await connection.getBalance(userHydrazine)); 
      if (hydrazineAcctBal.isZero()) {
          let token = new Token(connection,hydrazineMint,TOKEN_PROGRAM_ID,provider.wallet.publicKey);
      //    await token.createAssociatedTokenAccount(provider.wallet.publicKey);
          let tx = new web3.Transaction();
          tx.add(
              Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID,TOKEN_PROGRAM_ID,hydrazineMint,
                      userHydrazine,provider.wallet.publicKey,provider.wallet.publicKey)
          );
          const sign = await provider.wallet.sendTransaction(tx,connection);
          await connection.confirmTransaction(sign,'processed');
          toast("Hydrazine token account created",{
              duration: 2000,
              position: 'bottom-left',
              style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
              className: 'success-toast',
              icon: '✓',
          });
      }
      const starsAcctBal = new BN(await connection.getBalance(userStars));
      if (starsAcctBal.isZero()) {
        let token = new Token(connection,starsMint,TOKEN_PROGRAM_ID,provider.wallet.publicKey);
    //    await token.createAssociatedTokenAccount(provider.wallet.publicKey);
        let tx = new web3.Transaction();
        tx.add(
            Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID,TOKEN_PROGRAM_ID,starsMint,
                    userStars,provider.wallet.publicKey,provider.wallet.publicKey)
        );
        const sign = await provider.wallet.sendTransaction(tx,connection);
        await connection.confirmTransaction(sign,'processed');
        toast("Stars token account created",{
            duration: 2000,
            position: 'bottom-left',
            style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
            className: 'success-toast',
            icon: '✓',
        });
      }
      const transaction = new web3.Transaction();
      transaction.add(
        vaultProgram.instruction.closeBox(
          {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              vaultBox: vaultBox,
              boxOwner: accts.vaultAccount,
              mainVault: accts.mainVault,
              starsMint: starsMint,
              hydrazineMint: hydrazineMint,
              userStars: userStars,
              vaultStars: vaultStars,
              userHydrazine: userHydrazine,
              vaultHydrazine: vaultHydrazine,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
            },
        })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Vault Box Closed",{
        duration: 3000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
        className: 'success-toast',
        icon: '✓',
      });
      window.location.reload();
      // setVaultAccount(accts.vaultAccount);
      // await getAccountData();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message,{
          duration: 2000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
          color: "#FFF"},
          className: 'error-toast',
          icon: '⚠',
      });
    }
  }
  
  return (
    <main>
      <div className="page vault-page vh-100">
        <div className="container">
        <div className="row">
          <div className="col"><h3 className="page-title p-2">Fusion Vault</h3></div>
        </div>
        <div className="row d-flex justify-content-center m-4">
          <div className="col-md-6 col-lg-4 d-flex flex-column totals">
            <h5 className="totals-title">Total STARS staked</h5>
            <div className="totals-stats text-blue">{totalStaked}</div>
          </div>
           <div className="col-md-6 col-lg-4 d-flex flex-column totals">
            <h5 className="totals-title">Total STARS decayed</h5>
            <div className="totals-stats text-green">{totalDecayed}</div>
          </div>
          <div className="col-3 d-flex flex-column totals">
            <h5 className="totals-title">Total STARS penalized</h5>
            <div className="totals-stats text-orange">{totalPenalized}</div>
          </div>
        </div>
        <div className="row d-flex flex-row p-2 m-2 pb-4 mb-4">
            {/* 
              <DisplayVault />
              <DisplayVault />
              <DisplayVault />
              <DisplayVault />
              <CreateVault /> 
            */}
            { walletData === "fetching" || walletData === "not connected" ?
             <ConnectWallet /> :
             cadetAccount === null ? <MakeCadet /> :
             vaultAccount === null ? 
             <CreateAccount createAccountAction={createVaultAccount} /> :
             <>
             {vaultBoxes.map(function(d,idx) {
               return (
                <>
                 <DisplayVault mainVault={mainVault} vaultBox={d} claimVaultBox={claimVaultBox} closeBox={closeBox} />
                </>
               )
             })
              }
             <CreateVault 
             createVaultAction={createVault}
             boxId={newBoxId}/>
             </>
            }
        </div>
      </div>
      </div>
    </main>
  );
}

export default Vault;
