import React, {Fragment, useState, useEffect} from 'react';
import BigNumber from 'bignumber.js';
import Decimal from 'decimal.js-light';
import moment from 'moment';

import { Program, Provider, web3, BN, utils } from '@project-serum/anchor';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import toast from 'react-hot-toast';
import { useAnchorWallet, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { API_URL, privateClaimsProgramAdd } from '../../constants/constants';

import privateClaimsIdl from '../../web3/idl/privateClaims.json';

import "./index.css";
import { ProjectClaims } from "../../Components/Claims";
import { Anchor } from 'react-bootstrap';

function PrivateClaims(props) {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { SystemProgram, SYSVAR_RENT_PUBKEY } = web3;
  // const starsMint = new web3.PublicKey("FofXjmyFpNKGPUP9SJQk61zXe1zQAQEUo2BA7UT1CFFS");
  // const hydrazineMint = new web3.PublicKey("7MdRtgeHEYqfTattjttSEHkcdJP7kzKeWhBQZSqoNRVX");
  const claimsProgramName = "claims";
  const [provider, setProvider] = useState(null);
  const claimsProgramID = new web3.PublicKey(privateClaimsProgramAdd);
  const [walletData, setWalletData] = useState('fetching');
  const [claims, setClaims] = useState([]);
  const [claimInfo, setClaimInfo] = useState([]);
  const DEFAULT_TIMEOUT = 31000;
  useEffect(()=>{
    if (wallet.publicKey) {
        console.log(wallet.publicKey.toBase58());
        setWalletData('connected');
        setProvider(new Provider(connection,wallet,Provider.defaultOptions()));
        // let message = new TextEncoder().encode("Hello boy");
        // wallet.signMessage(message);
        // await getAccountData();
    } else {
      setWalletData('not connected');
    }
  }, [wallet.publicKey]);

  useEffect(() => { 
    if (provider) {
      fetch(API_URL+"/private-claims/", {
        "method": "POST",
        "headers": {"content-type": "application/json", "accept": "application/json"},
        "body": JSON.stringify({wallet: provider.wallet.publicKey.toBase58()})
        }).then(res=>res.json())
        .then((result)=>{
            console.log(result.data.claims);
            setClaims(result.data.claims);
          });
      // getAccountData();
    }
  },[provider]);

  useEffect(() => {
    if (claims.length > 0) {
      getAccountData();
    }
  },[claims])

  async function getAddys() {
    const [mainClaims, mainClaimsBump] = await web3.PublicKey.findProgramAddress(
      [Buffer.from(claimsProgramName)],
      claimsProgramID
    );
    return {mainClaims: mainClaims, mainClaimsBump: mainClaimsBump}
  }
  
  async function findATA(wallet,mint) {
    const ata = await web3.PublicKey.findProgramAddress([wallet.toBuffer(),TOKEN_PROGRAM_ID.toBuffer(),mint.toBuffer()],
                    ASSOCIATED_TOKEN_PROGRAM_ID);
    return ata[0];
  }

  const toBytes32Array = (b) => {
    const buf = Buffer.alloc(32);
    b.copy(buf, 32 - b.length);  
    return Array.from(buf);
  };

  async function getAccountData() {
    const accts = await getAddys();
    const claimsProgram = new Program(privateClaimsIdl, claimsProgramID, provider);
    // const mainClaimsNow = await claimsProgram.account.mainData.fetch(accts.mainClaims);
    let infoclaims = [];
    let curTime = moment().unix();
    console.log(claims);
    for (let i=0; i<claims.length; i++) {
      let infoClaim = claims[i];
      for (let j=0; j<infoClaim.tranches.length; j++) {
        if (Object.keys(infoClaim.tranches[j]).includes('amount')) {
          let trancheBN = new BN(infoClaim.tranches[j].no);
          let [distributor, distributorBump] = await web3.PublicKey.findProgramAddress(
            [Buffer.from(infoClaim.poolName), trancheBN.toArrayLike(Buffer, "le", 8)],
            claimsProgramID
          );
          let distributorInfo = await claimsProgram.account.merkleDistributor.fetch(distributor);
          infoClaim.tranches[j].claimStatus = distributorInfo.claimStatus;
          infoClaim.tranches[j].mint = distributorInfo.mint.toBase58();
          let [claimant, claimantBump] = await web3.PublicKey.findProgramAddress(
            [provider.wallet.publicKey.toBuffer(),
            Buffer.from(infoClaim.poolName),
            trancheBN.toArrayLike(Buffer, "le", 8),
            Buffer.from("ClaimStatus")
            ],
            claimsProgramID
          );
          const claimantInit = await connection.getBalance(claimant);
          if (claimantInit > 0) { infoClaim.tranches[j].claimed = true; } else { infoClaim.tranches[j].claimed = false; }
          if (curTime>distributorInfo.claimDate.toNumber()) { infoClaim.tranches[j].timePassed = true; } else { infoClaim.tranches[j].timePassed = false; }
        }
      }
      infoclaims.push(infoClaim);
    }
    setClaimInfo(infoclaims);
  }

  async function claimTokens(poolName, mint, tranche, amount, index, proof) {
    try {
      const accts = await getAddys();
      const claimsProgram = new Program(privateClaimsIdl, claimsProgramID, provider);
      const trancheBN = new BN(tranche);
      const [tokenVault, tokenVaultBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(poolName),trancheBN.toArrayLike(Buffer,"le",8),Buffer.from("token_vault")],
        claimsProgramID
      );
      const tokenMint = new web3.PublicKey(mint);
      const userTokens = await findATA(provider.wallet.publicKey, tokenMint);
      const userTokensBal = new BN(await connection.getBalance(userTokens));
      if (userTokensBal.isZero()) {
        let token = new Token(connection,tokenMint,TOKEN_PROGRAM_ID,provider.wallet.publicKey);
    //    await token.createAssociatedTokenAccount(provider.wallet.publicKey);
        let tx = new web3.Transaction();
        tx.add(
            Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID,TOKEN_PROGRAM_ID,tokenMint,
                    userTokens,provider.wallet.publicKey,provider.wallet.publicKey)
        );
        const sign = await provider.wallet.sendTransaction(tx,connection);
        await connection.confirmTransaction(sign,'processed');
        toast("Token account created",{
            duration: 2000,
            position: 'bottom-left',
            style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                color: "#FFF"},
            className: 'success-toast',
            icon: '✓',
        });
      }
      const [claimStatus, claimStatusBump] = await web3.PublicKey.findProgramAddress(
        [provider.wallet.publicKey.toBuffer(),Buffer.from(poolName),trancheBN.toArrayLike(Buffer,"le",8),Buffer.from("ClaimStatus")],
        claimsProgramID
      );
      const [distributor, distributorBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(poolName),trancheBN.toArrayLike(Buffer,"le",8)],
        claimsProgramID
      );
      const transaction = new web3.Transaction();
      transaction.add(
        claimsProgram.instruction.claim(
          new BN(index),
          new BN(amount),
          proof.map((p) => toBytes32Array(Buffer.from(p,"hex"))),
          {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              claimStatus: claimStatus,
              distributor: distributor,
              userToken: userTokens,
              tokenVault: tokenVault,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
              rent: web3.SYSVAR_RENT_PUBKEY
            },
        })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Tranche Claimed",{
        duration: 3000,
        position: 'bottom-left',
        style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
                  color: "#FFF"},
        className: 'success-toast',
        icon: '✓',
      });
      window.location.reload();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message,{
          duration: 2000,
          position: 'bottom-left',
          style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
          color: "#FFF"},
          className: 'error-toast',
          icon: '⚠',
      });
    }
  }

  return (
    <main>
      <div className="page claims-page vh-100">
        <div className="container">
        <div className="row">
          <div className="col"><h3 className="page-title p-2">Claims</h3></div>
        </div>
        <div className="row d-flex flex-row p-2 m-2 pb-4 mb-4">
            {/* <div>
                <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                </label>
                <span class="toggle-side-text">Only My Claims</span>
            </div> */}
            {claimInfo.map(function(d,idx) {
                return (
                  <ProjectClaims data={d} claimTokens={claimTokens}/>
                )
              })
            }
        </div>
      </div>
      </div>
    </main>
  );
}

export default PrivateClaims;
