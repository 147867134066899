import React, { useEffect, useState } from "react";
import Leftnav from "../Leftnav";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import "./index.css";

function Header() {
   const [isTransparent, setIsTransparent] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setIsTransparent(window.pageYOffset > 50)
      );
    }
  }, []);

  return (
    <header>
      <Leftnav />
      <nav
        id="main-navbar"
        className={`navbar navbar-expand-lg fixed-top ${ isTransparent ? "navbar-sl" : ""}`}
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <svg
              width="35"
              height="32"
              viewBox="0 0 35 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <ellipse
                cx="17.619"
                cy="14.6379"
                rx="13.661"
                ry="13.6379"
                stroke="white"
                strokeWidth="2"
                style={{ mixBlendMode: "screen" }}
              />
              <path
                d="M1 31L17.5 14.528L34 31"
                stroke="white"
                strokeWidth="2"
                style={{ mixBlendMode: "screen" }}
              />
            </svg>

            <div className="header-title">
              <span className="heading">CADET</span>
              <span className="sub-heading">DASHBOARD</span>
            </div>
          </a>

          <ul className="navbar-nav ms-auto d-flex flex-row">
            <li className="nav-item notification">
              <a
                className="nav-link me-3 me-lg-0 hidden-arrow"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-mdb-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  width="18"
                  height="22"
                  viewBox="0 0 18 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.02009 1.91016C5.71009 1.91016 3.02009 4.60016 3.02009 7.91016V10.8002C3.02009 11.4102 2.76009 12.3402 2.45009 12.8602L1.30009 14.7702C0.590088 15.9502 1.08009 17.2602 2.38009 17.7002C6.69009 19.1402 11.3401 19.1402 15.6501 17.7002C16.8601 17.3002 17.3901 15.8702 16.7301 14.7702L15.5801 12.8602C15.2801 12.3402 15.0201 11.4102 15.0201 10.8002V7.91016C15.0201 4.61016 12.3201 1.91016 9.02009 1.91016Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M10.8702 2.19994C10.5602 2.10994 10.2402 2.03994 9.91017 1.99994C8.95017 1.87994 8.03017 1.94994 7.17017 2.19994C7.46017 1.45994 8.18017 0.939941 9.02017 0.939941C9.86017 0.939941 10.5802 1.45994 10.8702 2.19994Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.02 18.0601C12.02 19.7101 10.67 21.0601 9.02002 21.0601C8.20002 21.0601 7.44002 20.7201 6.90002 20.1801C6.36002 19.6401 6.02002 18.8801 6.02002 18.0601"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                </svg>
              </a>
            </li>
            {/*
            <li className="nav-item me-3 me-lg-0">
              <div className="m-2 kyc-verified"> KYC VERIFIED </div>
            </li>
            */}
            <li className="nav-item me-3 me-lg-0">
              <a className="nav-link" href="#">
                {/* <img src="images/profile.png" /> */}
                <WalletMultiButton />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
