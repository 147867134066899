import React, { useMemo } from 'react';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    getPhantomWallet,
    getSlopeWallet,
    getSolflareWallet,
    getCoin98Wallet
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import {
  Switch,
  Route
} from "react-router-dom";

import "./App.css";
import Header from "./Components/Header";
import Home from "./screens/Home";
import Vault from "./screens/Vault";
import Tokens from "./screens/Tokens";
import Ido from "./screens/Ido";
import Claims from "./screens/Claims";
import PrivateClaims from "./screens/PrivateClaims";

import { Toaster  } from 'react-hot-toast';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

function App() {
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => "https://warmhearted-withered-pool.solana-mainnet.quiknode.pro/5bc4b12f008b54ce7f9c9bd93fcba5287e1166bb/");
  // const endpoint = useMemo(() => "https://quiet-quiet-sea.solana-mainnet.quiknode.pro/");
  const wallets = useMemo(() => [
    getPhantomWallet(),
    getCoin98Wallet(),
    getSolflareWallet(),
    getSlopeWallet()
], [network]);
  return (
    <CacheBuster currentVersion={version} isEnabled>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <Header />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/vault" exact component={Vault} />
              <Route path="/sales" exact component={Tokens} />
              <Route path="/sale-detail" exact component={Ido} />
              <Route path="/claims" exact component={Claims} />
              <Route path="/private" exact component={PrivateClaims} />
            </Switch>
            <Toaster />
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </CacheBuster>
  );
}

export default App;