import ProgressBar from "../ProgressBar";
import "./index.css";

function StatusBarTop(props) {
  const { name, mp, lvl, percentage } = props;
  return (
    <div className="top-officers-stats w-100">
      <ProgressBar percentage={percentage} />
      <div className="headings d-flex justify-content-between">
       {props.children}
      </div>
    </div>
  );
}

export default StatusBarTop;
