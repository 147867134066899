import { useState } from "react";
import "./index.css";

function Mission(props) {
  const { title, description } = props;

  const [collapse, setCollapse] = useState(true);

  const expandPanel = () => {
    setCollapse(false);
  };

  const collapsePanel = () => {
    setCollapse(true);
  };

  return (
    <div className="mission text-uppercase d-flex">
      <svg
        width="70"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.24">
          <path
            d="M15 2.003V2H4.00198C3.44998 2 2.99998 2.455 2.99998 2.992V21.008C3.00024 21.2712 3.10498 21.5235 3.29117 21.7095C3.47737 21.8955 3.72979 22 3.99298 22H20.007C20.1383 21.9991 20.2682 21.9723 20.3891 21.9212C20.5101 21.8701 20.6198 21.7957 20.712 21.7022C20.8042 21.6087 20.8771 21.4979 20.9265 21.3762C20.9759 21.2545 21.0009 21.1243 21 20.993V8L15 2.003ZM18.17 8H15V4.83L18.17 8ZM13 4V9C13 9.26522 13.1053 9.51957 13.2929 9.70711C13.4804 9.89464 13.7348 10 14 10H19V20H4.99998V4H13Z"
            fill="white"
          />
        </g>
      </svg>

      <div className="px-2">
        <div className="title">{title}</div>
        <div className="description blurred">
          <h4>Description</h4>
          <p className="text-capitalize">{description}</p>
        </div>

        <div className="footer d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <div onClick={collapsePanel}>
              {!collapse ? (
                <svg
                  width="20"
                  height="24"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2679 3C11.0377 1.66667 12.9623 1.66667 13.7321 3L20.6603 15C21.4301 16.3333 20.4678 18 18.9282 18H5.0718C3.5322 18 2.56995 16.3333 3.33975 15L10.2679 3Z"
                    fill="#FF6B00"
                  />
                  <path
                    d="M10.56 14.12V10.28L11.44 9.4H13.84L14.688 10.248V10.76H14.08V10.448L13.568 9.944H11.712L11.168 10.48V13.92L11.712 14.456H13.568L14.08 13.952V13.64H14.688V14.152L13.84 15H11.44L10.56 14.12Z"
                    fill="#0B0B0B"
                  />
                </svg>
              ) : (
                <svg
                  width="20"
                  height="24"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.4">
                    <path
                      d="M13.299 3.25C12.7217 2.25 11.2783 2.25 10.701 3.25L3.77276 15.25C3.19541 16.25 3.9171 17.5 5.0718 17.5L18.9282 17.5C20.0829 17.5 20.8046 16.25 20.2272 15.25L13.299 3.25Z"
                      stroke="white"
                    />
                    <rect
                      opacity="0.4"
                      x="14"
                      y="12"
                      width="4"
                      height="1"
                      transform="rotate(-180 14 12)"
                      fill="white"
                    />
                  </g>
                </svg>
              )}
            </div>
            <div onClick={expandPanel}>
              {collapse ? (
                <svg
                  width="26"
                  height="24"
                  viewBox="0 0 26 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.7321 21C12.9623 22.3333 11.0377 22.3333 10.2679 21L3.33975 9C2.56995 7.66667 3.5322 6 5.0718 6L18.9282 6C20.4678 6 21.4301 7.66667 20.6603 9L13.7321 21Z"
                    fill="#FF6B00"
                  />
                  <path
                    d="M9.64 9.4H13.256V9.944H10.248V11.912H13.024V12.456H10.248V14.456H13.256V15H9.64V9.4Z"
                    fill="#0B0B0B"
                  />
                </svg>
              ) : (
                <svg
                  width="26"
                  height="24"
                  viewBox="0 0 26 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.4">
                    <path
                      d="M10.701 20.75C11.2783 21.75 12.7217 21.75 13.299 20.75L20.2272 8.75C20.8046 7.75 20.0829 6.5 18.9282 6.5H5.0718C3.9171 6.5 3.19541 7.75 3.77276 8.75L10.701 20.75Z"
                      stroke="white"
                    />
                    <rect
                      opacity="0.4"
                      x="10"
                      y="12"
                      width="4"
                      height="1"
                      fill="white"
                    />
                  </g>
                </svg>
              )}
            </div>

            <span className="px-1">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9" cy="9" r="8.5" stroke="#C6C6C6" />
                <circle cx="9" cy="9" r="2" fill="#C6C6C6" />
                <path d="M5 1.5L9 9" stroke="#C6C6C6" />
              </svg>
            </span>
            <span className="px-1">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9" cy="9" r="8.5" stroke="#C6C6C6" />
                <circle cx="9" cy="9" r="2" fill="#C6C6C6" />
                <path d="M5 16.5L9 9" stroke="#C6C6C6" />
              </svg>
            </span>
          </div>
          {!collapse && (
            <div className="btn-wrapper text-end">
              <div className="btn secondry mx-1">Ignore</div>
              <div className="btn primary">Approve</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Mission;
