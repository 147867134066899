import ProgressBar from "../ProgressBar";
import "./index.css";

function TopOfficersStats(props) {
  const { name, mp, lvl, percentage } = props;
  return (
    <div className="top-officers-stats w-100">
      <ProgressBar percentage={percentage} />
      <div className="headings d-flex justify-content-between">
        <span>{name}</span>
        <span>{mp}</span>
        <span>{lvl}</span>
      </div>
    </div>
  );
}



export default TopOfficersStats;
